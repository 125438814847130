import React, { useEffect, useState } from 'react';
import { loadStripe } from "@stripe/stripe-js";
import { Button } from 'react-bootstrap';
import axios from "axios";

const serverConfig = require("../config/server.js");

const stripePromise = loadStripe("pk_test_51ObPOnSHomqqZI0rj1EtaJZp7CxHQYI25mmOm3n6WHztJa2pZCUIWdKndEx3KGGmwKP8FxVdDNx9Dh7azuZBDpkD004YNpyIpH");

const CheckoutButton = ({token, handleLogout}) => {
  const [currency, setCurrency] = useState(null);
  const [amount, setAmount] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    if(token !== null){
      axios.post(serverConfig.api_base_url + "org_currency_get",{token: token})
      .then(response=>{
        console.log('org_currency_get', response);
        if(response.data.status === 200) {
          setCurrency(response.data.currency);
          setAmount(response.data.amount);
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));
    }
  },[token]);

  const handleClick = async () => {
    setIsProcessing(true);
    const response = await fetch(serverConfig.fapi_base_url + 'create_checkout_session', {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        amount:amount,
        currency: currency,
        product_name: "Application Process",
        return_url: window.location.href,
        cancel_url: window.location.href,
      }),
    });
    //setIsProcessing(false);
    const { sessionId } = await response.json();
    const stripe = await stripePromise;
    const { error } = await stripe.redirectToCheckout({ sessionId });

    if (error) {
      console.error("Error redirecting to Checkout:", error);
    }
  };

  return <Button type="button" variant="primary" className="w-100 mt-3" onClick={handleClick}>{isProcessing ? "Processing ... " : "Complete Payment"}</Button>;
};

export default CheckoutButton;