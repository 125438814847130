import React, {useState, useEffect} from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import axios from "axios";
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/styles.css';
import UserService from './services/UserService.js';
import RenderOnLogin from './RenderOnLogin';
import Landing from './pages/Landing';
import Report from './pages/Report';
import InviteUser from './components/usersignup/InviteUser';
import Signup from './components/usersignup/Signup';
import Login from './components/usersignup/Login';
import RegisterUser from './components/usersignup/RegisterUser';
import BankerDashboard from './pages/BankerDashboard';
import ApplicationDetails from './pages/ApplicationDetails.jsx';
import ApplicationDetailsEdit from './pages/ApplicationDetailsEdit.jsx';
import UserRegistration from './components/usersignup/UserRegistration.jsx';
import KYCValidation from './pages/KYCValidation.jsx';
import SaaSAgreement from './pages/SaaSAgreement.jsx';
import TermsOfService from './pages/TermsOfService.jsx';
import CustomerApplication from './pages/CustomerApplication.jsx';
import CustomerApplications from './pages/CustomerApplications.jsx';

const serverConfig = require("./config/server.js");

function App() {
  useEffect(() => {
    /* const cssJson = {
      color1: "#F2F2F2",
      color2: "#FFFFFF",
      color3: "#666666",
      color4: "#CCCCCC",
      color5: "#FF3232",
      color6: "#000000",
      color7: "#13AE5C",
      color8: "#F5F9FD",
      color9: "#E6E6E6",
      color10: "#000000",

      primary: "#FDC600",
      primary10: "#FEFCF7",
      primary200: "#FFDA58",
      primary300: "#FDC635",
      primary700: "#D0B467",
      colorDefault: "#242424",
      fontSizeDefault: "14px",
      fontFamilyDefault: "'Poppins', sans-serif",
    }; */

    axios.post(serverConfig.api_base_url + "get_favicon_image",{domainName:window.location.host})
    .then(response=>{
      console.log('get_favicon_image response', response);
      if(response.data.status === 200) {
        var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';
        link.href = response.data.faviconBase64;
        document.getElementsByTagName('head')[0].appendChild(link);
      } else {
        console.log(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));

    axios.post(serverConfig.api_base_url + "get_theme_css",{domainName:window.location.host})
    .then(response=>{
      console.log('get_theme_css response', response);
      if(response.data.status === 200) {
        const cssJson = response.data.items;
        const root = document.documentElement;

        root.style.setProperty('--color1', cssJson.color1);
        root.style.setProperty('--color2', cssJson.color2);
        root.style.setProperty('--color3', cssJson.color3);
        root.style.setProperty('--color4', cssJson.color4);
        root.style.setProperty('--color5', cssJson.color5);
        root.style.setProperty('--color6', cssJson.color6);
        root.style.setProperty('--color7', cssJson.color7);
        root.style.setProperty('--color8', cssJson.color8);
        root.style.setProperty('--color9', cssJson.color9);
        root.style.setProperty('--color10', cssJson.color10);

        root.style.setProperty('--primary', cssJson.primary);
        root.style.setProperty('--primary-10', cssJson.primary10);
        root.style.setProperty('--primary-200', cssJson.primary200);
        root.style.setProperty('--primary-300', cssJson.primary300);
        root.style.setProperty('--primary-700', cssJson.primary700);
        root.style.setProperty('--colorDefault', cssJson.colorDefault);
        root.style.setProperty('--fontSizeDefault', cssJson.fontSizeDefault);
        root.style.setProperty('--fontFamilyDefault', cssJson.fontFamilyDefault);
      } else {
        console.log(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<RenderOnLogin><Landing /></RenderOnLogin>} />
        <Route path="/customer" element={<RenderOnLogin><Report /></RenderOnLogin>} />
        <Route path="/kyc-validation" element={<KYCValidation />} />
        {/* User Signup */}
        <Route path='/signup' element={<RegisterUser/>}/>
        <Route path='/register' element={<UserRegistration/>}/>
        <Route path='/company' element={<RenderOnLogin><CustomerApplication/></RenderOnLogin>}/>
        <Route path='/customer-applications' element={<RenderOnLogin><CustomerApplications/></RenderOnLogin>}/>
        {/* Banker Dashboard */}
        <Route path='/applications' element={<RenderOnLogin><BankerDashboard/></RenderOnLogin>}/>
        <Route path='/application-details' element={<RenderOnLogin><ApplicationDetails/></RenderOnLogin>}/>
        <Route path="/application-details-edit" element={<RenderOnLogin><ApplicationDetailsEdit /></RenderOnLogin>} />
        <Route path="/SaaSAgreement" element={<SaaSAgreement />} />
        <Route path="/TermsOfService" element={<TermsOfService />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
