import React, {useState, useEffect} from 'react';
import { Container, Row, Col, Form, Button, Modal, Accordion, Table} from 'react-bootstrap';
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import Header from '../components/Header';
import UserService from '../services/UserService';
import HashUtil from '../services/HashUtil';

const serverConfig = require("../config/server.js");

const ApplicationDetailsEdit = () => {

  const navigate = useNavigate();

  const [mToken, setMToken] = useState(sessionStorage.getItem('token'));

  var url = window.location.href;
  var applicationID = url.substring(url.indexOf('?')+1).split('#')[0];
  console.log();
  console.log('applicationID', applicationID);

  const [appData, setAppData] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [lastUpdated, setLastUpdated] = useState('');
  const [inputs, setInputs] = useState([]);
  const [boInputs, setBoInputs] = useState([]);
  const [caInputs, setCaInputs] = useState([]);
  const [boInputsArr, setBoInputsArr] = useState([]);
  const [caInputsArr, setCaInputsArr] = useState([]);
  const [userPrivilegedEditApplication, setUserPrivilegedEditApplication] = useState(false);

  const getToken = () => {
    let jwt = UserService.getToken();
    axios.post(serverConfig.api_base_url + "user_get_token",{jwt:jwt, domain:window.location.hostname})
    .then(response=>{
      console.log('user_get_token response', response);
      if(response.data.status === 200){
        sessionStorage.setItem('token', response.data.token);
        setMToken(response.data.token);
      } else if(response.data.status >= 400 && response.data.status <= 499){
        alert(response.data.message);
      } else if(response.data.jwt_validation === 'fail') {
        handleLogout();
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const handleLogout = () => {
    UserService.doLogout();
    sessionStorage.clear();
    localStorage.clear();
  }

  const kycDetailsGet = () => {
    /* axios.post(serverConfig.api_base_url + "kyc_details_get",{token: mToken, app_id:applicationID})
      .then(response=>{
        console.log('kyc_details_get', response);
        if(response.data.status === 200) {
          setKycDetails(response.data);
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err)); */
  }

  const getAppData = () => {
    axios.post(serverConfig.api_base_url + "get_app_data",{token:mToken, application_id:applicationID})
    .then(response=>{
      setIsLoading(false);
      console.log(mToken);
      console.log("get_app_data response:", response);
      console.log(response.data.status);
      if(response.data.status === 200){
        if(response.data.is_open === 1){
          navigate("/application-details?" + applicationID);
        }
        setAppData(response.data.items);
        setLastUpdated(response.data.last_updated_at);
        if(response.data.items !== null){
          let res = response.data.items;
          let boGroup = res.filter(el => el.group_name === "beneficial_owner_information");
          console.log(boGroup);
          let boSection = boGroup[0]?.group_sections?.filter(section => section.section_name === "beneficial_owner_information");
          if(boSection.length > 0){
            let boInfo = boSection[0]?.section_entities?.filter(entities => entities.entity_name === "boInformation");
            console.log(JSON.parse(boInfo[0]?.entity_data));
            if(boInfo[0]?.entity_data){
              setBoInputs(JSON.parse(boInfo[0]?.entity_data));
            } else {
              setBoInputs([]);
            }
          }
          
          let caGroup = res.filter(el => el.group_name === "company_applicant_information");
          console.log(caGroup);
          let caSection = caGroup[0]?.group_sections?.filter(section => section.section_name === "company_applicant_information");
          if(caSection.length > 0){
            let caInfo = caSection[0]?.section_entities?.filter(entities => entities.entity_name === "companyApplicantInformation");
            console.log(JSON.parse(caInfo[0]?.entity_data));
            if(caInfo[0]?.entity_data){
              setCaInputs(JSON.parse(caInfo[0]?.entity_data));
            } else {
              setCaInputs([]);
            }
          }
        }
      } else if(response.data.status === 407){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  useEffect(() => {
    console.log(sessionStorage.getItem('token'));
    if(sessionStorage.getItem('token') === null){
      getToken();
    } else {
      setMToken(sessionStorage.getItem('token'));
    }
  }, []);

  useEffect(() => {
    if(mToken !== null && mToken !== ''){
      /* application_data_get */
      console.log('mToken', mToken);

      axios.post(serverConfig.api_base_url + "user_check_privilege",{token: mToken, privilege: "submit_data"}).then(response=>{
        console.log('user_check_privilege response', response);
        if(response.data.status === 200) {
          if(response.data.is_privileged === 1){
            navigate("/customer");
          } else if(response.data.is_privileged === 0) {
            axios.post(serverConfig.api_base_url + "user_check_privilege",{token: mToken, privilege: "application_list_read"}).then(response=>{
              console.log('user_check_privilege response', response);
              if(response.data.status === 200) {
                if(response.data.is_privileged === 1){
                  //navigate("/applications");
                  getAppData();
                  console.log(appData);
                } else if(response.data.is_privileged === 0) {
                  alert("Unauthorized Access");
                  handleLogout();
                }
              } else if(response.data.status === 301){
                handleLogout();
              } else {
                alert(response.data.message);
              }
            })
            .catch(err=>console.log("error is",err));
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));

      axios.post(serverConfig.api_base_url + "user_check_privilege", {token: mToken, privilege: "application_details_update"})
      .then((response) => {
        if (response.data.status === 200) {
          if (response.data.is_privileged === 0) {
            setUserPrivilegedEditApplication(false);
          } else if (response.data.is_privileged === 1) {
            setUserPrivilegedEditApplication(true);
          }
        } else if (response.data.status === 301) {
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch((err) => console.log("error is", err));
    }
  }, [mToken]);

  const [userPrivilegedManualOverrideKyc, setUserPrivilegedManualOverrideKyc] = useState(false);
  const [showConfirmationOverrideKyc, setShowConfirmationOverrideKyc] = useState(false);
  const handleCloseConfirmationOverrideKyc = () => setShowConfirmationOverrideKyc(false);
  const handleShowConfirmationOverrideKyc = () => setShowConfirmationOverrideKyc(true);

  const handleOverrideKyc = () => {
    axios.post(serverConfig.api_base_url + "kyc_approve_manually", {token: mToken, app_id:applicationID})
    .then((response) => {
      if (response.data.status === 200) {
        handleCloseConfirmationOverrideKyc();
        kycDetailsGet();
      } else if (response.data.status === 301) {
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch((err) => console.log("error is", err));
  }

  const [imageData, setImageData] = useState(null);
  const [showImage, setShowImage] = useState(false);
  const handleCloseImage = () => {
    setShowImage(false);
  }

  const handleChangeBOInput = (event, index) => {
    const name = event.target.name;
    let value = event.target.value;
    console.log(value);
  
    console.log(boInputs);
    console.log(...boInputs);

    if(name === "boDocumentIdType"){
      if(value === "U.S. passport"){
        boInputs[index]['boDocumentIdIssuingCountryJurisdiction'] = "United States";
        boInputs[index]['boDocumentIdIssuingCountryJurisdictionCode'] = "US";
      }
    }

    if(event.target.type === 'checkbox'){
      if(event.target.checked){
        boInputs[index][name] = value;
      } else {
        boInputs[index][name] = "";
      }
    } else if(event.target.type === 'file'){
      var extension = value.split('.').pop();
      if(event.target.accept === '.pdf, .jpeg, .jpg, .png'){
        if(extension=='pdf' || extension=='jpeg' || extension=='jpg' || extension=='png'){
          console.log("File type matched");
          var file = document.querySelector('#'+event.target.id).files[0];
          console.log(file.size);
          let fileSizeInMB = file.size  / 1048576;
          console.log(fileSizeInMB);
          if(file !== undefined) {
            let filename = file.name;
            var fileData;
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
              fileData = reader.result;
              if(fileSizeInMB <= 4){
                boInputs[index][name] = filename;
                boInputs[index][name+'Base64'] = fileData;
              } else {
                alert('File size exceeds maximum limit of 4MB. Please try again.');
                event.target.value = '';
              }
            };
            reader.onerror = function (error) {
              console.log('Error: ', error);
            };
          }
        } else {
          alert("Only .pdf, .jpeg, .jpg, .png file is allowed");
          event.target.value = null;
        }
      }
    } else {
      boInputs[index][name] = value;
    }

    setBoInputs(boInputs);
    setBoInputsArr(boInputs);//only if there is change in BO Information
    console.log(boInputs);
  }

  const handleChangeCAInput = (event, index) => {
    const name = event.target.name;
    let value = event.target.value;
    console.log(value);
  
    console.log(caInputs);
    console.log(...caInputs);

    if(name === "applicantDocumentIdType"){
      if(value === "U.S. passport"){
        caInputs[index]['applicantDocumentIdIssuingCountryJurisdiction'] = "United States";
      }
    }

    if(event.target.type === 'checkbox'){
      if(event.target.checked){
        caInputs[index][name] = value;
      } else {
        caInputs[index][name] = "";
      }
    } else if(event.target.type === 'file'){
      var extension = value.split('.').pop();
      if(event.target.accept === '.pdf, .jpeg, .jpg, .png'){
        if(extension=='pdf' || extension=='jpeg' || extension=='jpg' || extension=='png'){
          console.log("File type matched");
          var file = document.querySelector('#'+event.target.id).files[0];
          console.log(file.size);
          let fileSizeInMB = file.size  / 1048576;
          console.log(fileSizeInMB);
          if(file !== undefined) {
            let filename = file.name;
            var fileData;
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
              fileData = reader.result;
              if(fileSizeInMB <= 4){
                caInputs[index][name] = filename;
                caInputs[index][name+'Base64'] = fileData;
              } else {
                alert('File size exceeds maximum limit of 4MB. Please try again.');
                event.target.value = '';
              }
            };
            reader.onerror = function (error) {
              console.log('Error: ', error);
            };
          }
        } else {
          alert("Only .pdf, .jpeg, .jpg, .png file is allowed");
          event.target.value = null;
        }
      }
    } else {
      caInputs[index][name] = value;
    }

    setCaInputs(caInputs);
    setCaInputsArr(caInputs);//only if there is change in BO Information
    console.log(caInputs);
  }

  const handleDataChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    
    var newInputs = inputs;
    var matched = 0;
    for(var i = 0; i < newInputs.length && !matched; ++i) {
      if (newInputs[i]['entity_name'] == name) {
        console.log('Found entity_name ' + name + ' at position ' + i + ' new value: ' + value);

        if(event.target.type === 'checkbox'){
          if(event.target.checked){
            newInputs[i]['data'] = value;
          } else {
            newInputs[i]['data'] = "";
          }
        } else {
          newInputs[i]['data'] = value;
        }

        matched = 1;
      }
    }

    if (!matched) {
      setInputs([...inputs, {'entity_name': name, 'data': value}]);
    } else {
      setInputs(newInputs);
    }
    console.log(inputs);
  }

  const handleSaveData = () => {
    setIsLoading(true);
    console.log(inputs);
    //let finalInputs = boInputsArr.length > 0 ? [...inputs, {'entity_name': 'boInformation', 'data': boInputsArr}] : inputs;

    console.log("boInputsArr", boInputsArr);
    console.log("boInputsArr", caInputsArr);

    let finalBOInputs = boInputsArr.length > 0 ? [{'entity_name': 'boInformation', 'data': boInputsArr}] : [];
    let finalCAInputs = caInputsArr.length > 0 ? [{'entity_name': 'companyApplicantInformation', 'data': caInputsArr}] : [];

    let finalInputs = [...finalBOInputs, ...finalCAInputs, ...inputs];

    console.log("finalInputs", finalInputs);
    axios.post(serverConfig.api_base_url + "application_data_update",{token:mToken, application_id:applicationID, items: finalInputs})
    .then(response=>{
      console.log("application_data_update response:", response);
      if(response.data.status === 200){
        navigate('/application-details?'+applicationID);
        setIsLoading(false);
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
        setIsLoading(false);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const handleUpload = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    //console.log(value);
    var extension = value.split('.').pop();
    //console.log(extension);

    if(extension=='png' || extension=='jpg' || extension=='pdf'){
      setIsLoading(true);
      console.log("File type matched");
      var file = document.querySelector('#'+event.target.id).files[0];
      console.log(file.size);
      let fileSizeInMB = file.size  / 1048576;
      console.log(fileSizeInMB);

      if(file !== undefined) {
        let filename = file.name;
        var fileData;
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          fileData = reader.result;
          if(fileSizeInMB <= 4){
            axios.post(serverConfig.api_base_url + "application_file_set",{token:mToken, customer_id:applicationID, entity_name:name, file_name: filename, file_data: fileData})
            .then(response=>{
              console.log('set_file response', response);
              if(response.data.status === 200) {
                setIsLoading(false);
                console.log('success');
                getAppData();
              } else if(response.data.status === 301){
                handleLogout();
              } else {
                alert(response.data.message);
                setIsLoading(false);
                document.getElementsByName(name)[0].value = '';
              }
            })
            .catch(err=>console.log("error is",err));
          } else {
            setIsLoading(false);
            handleShowErrorPopup('File size exceeds maximum limit of 4MB. Please try again.');
            event.target.value = '';
          }
        };
        reader.onerror = function (error) {
          console.log('Error: ', error);
        };
      }
    } else {
      alert("Only png, jpg, pdf files are allowed");
      event.target.value = null;
    }
  }

  const [errorPopupText, setErrorPopupText] = useState(null);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const handleCloseErrorPopup = () => {
    setShowErrorPopup(false);
    setErrorPopupText(null);
  }
  const handleShowErrorPopup = (error_text) => {
    setErrorPopupText(error_text);
    setShowErrorPopup(true);
  }
  
  return (
    <>
      <Header isLoggedIn={(mToken !== null && mToken !== undefined && mToken !== '') ? true : false} isAdmin={true} />
      <main className='application-details'>
        {isLoading && (<div id="loading"></div>)}
        <section>
          <Container fluid>
            <div className='page-title justify-content-space-between'>
              <div className='d-flex'>
                <div className='back-link'><Link to={"/application-details?" + applicationID} className="btn-icon"><img src={require('./../assets/images/back-icon.png')} alt="Back" /></Link></div>
                <h1>Edit Application Details</h1>
              </div>
              <Button variant="primary" onClick={handleSaveData} className='ms-auto px-5'>Save</Button>
            </div>
          </Container>
        </section>
        {appData.length > 0 && appData.map((item, index) => {
          return(item.group_disabled === 0 && item.group_name !== "review_and_edit" && <section className='bdr-btm' style={{backgroundColor: (index % 2) == 1 ? "#efefef" : ""}}>
            <Container fluid>
              <h2>
                {item.group_name === "agreements_disclosures" && <img src={require('./../assets/images/agreements-icon.png')} alt="Agreements Icon" className='me-4' />}
                {item.group_label}
              </h2>
              
              {item.group_sections.length > 0 && item.group_sections.sort((a,b) => a.section_seq_no - b.section_seq_no).map((sectionItem, sectionIndex) => {
                return(sectionItem.section_visible === 1 && <div className='mb-3'>
                  {(sectionItem.section_title && item.group_name !== "agreements_disclosures") && <h3 style={{backgroundColor: "#cccccc"}} className='p-2'>{sectionItem.section_title}</h3>}
                  {sectionItem.section_entities.length > 0 && <Row xs={(sectionItem.section_name === "beneficial_owner_information" || item.group_name === "agreements_disclosures") ? 12 : 4}>
                    {sectionItem.section_entities.sort((a,b) => a.entity_seq_no - b.entity_seq_no).map((entityItem, entityIndex) => {
                      return(<>
                        {(entityItem.entity_visible === 1 || (entityItem.entity_visible === 0 && entityItem.entity_name.endsWith("Code"))) && <Col className='mt-2 mb-2'>

                          {item.group_name === "payment" && <>{<img src={entityItem.entity_data === "true" ? require('./../assets/images/check-icon.png') : require('./../assets/images/uncheck-icon.png')} alt="Check Icon" className='me-2' />}<span>Payment Complete</span></>}

                          {item.group_name === "agreements_disclosures" && sectionItem.section_name !== "application_review" && <>{<img src={entityItem.entity_data === "true" ? require('./../assets/images/check-icon.png') : require('./../assets/images/uncheck-icon.png')} alt="Check Icon" className='me-2' />}<span dangerouslySetInnerHTML={{__html:entityItem.entity_label || entityItem.entity_name}} /></>}

                          {item.group_name === "agreements_disclosures" && sectionItem.section_name === "application_review" && <>
                            <span className='label' dangerouslySetInnerHTML={{__html:entityItem.entity_label || entityItem.entity_name}} />: {entityItem.entity_name === "rating" ? <div className="star-rating">
                              {[...Array(5)].map((star, index) => {
                                index += 1;
                                return (
                                  <Button variant="star" type="button" key={index} className={index <= entityItem.entity_data ? "on" : "off"}></Button>
                                );
                              })}
                            </div> : entityItem.entity_data}
                          </>}

                          {item.group_name !== "payment" && item.group_name !== "agreements_disclosures" && <>
                            <span className='label' dangerouslySetInnerHTML={{__html:entityItem.entity_label || entityItem.entity_name}} /> :
                            <div className='value' style={{maxHeight: entityItem.entity_data_type.verify_id === 1 ? "100px" : "none"}}>
                              {
                                entityItem.entity_data_type.type === "image" ? entityItem.entity_data && <img src={'data:image/jpeg;base64,'+entityItem.entity_data} height="80" alt="Image" type="button" onClick={() => {setImageData('data:image/jpeg;base64,'+entityItem.entity_data); setShowImage(true);}} /> 
                                : <>
                                  {entityItem.entity_data_type.boInfo === 1 ? entityItem.entity_data && <Accordion>
                                    {JSON.parse(entityItem.entity_data).map((boItem, boIndex) => {
                                      console.log(boItem);
                                      return(<Accordion.Item eventKey={`${boIndex}`} className="mb-3">
                                        <Accordion.Header>Beneficial Owner #{boIndex+1}</Accordion.Header>
                                        <Accordion.Body>
                                          <Row xs={4}>
                                            <Col className="mt-2 mb-2"><span className='label'>Parent/Guardian information instead of minor child</span> : <div><input 
                                                name="isParentGuardianInformation" 
                                                defaultValue={boItem.isExemptEntity !== '' ? "" : boItem.isParentGuardianInformation}
                                                onChange={(e) => handleChangeBOInput(e, boIndex)} 
                                                placeholder="Parent/Guardian information instead of minor child"
                                                type="text" 
                                              /></div>
                                            </Col>
                                            <Col className="mt-2 mb-2"><span className='label'>FinCEN ID</span> : <div><input 
                                                name="boFincenId" 
                                                defaultValue={boItem.isExemptEntity !== '' ? "" : boItem.boFincenId}
                                                onChange={(e) => handleChangeBOInput(e, boIndex)} 
                                                type="text" 
                                              /></div>
                                            </Col>
                                            <Col className="mt-2 mb-2"><span className='label'>Exempt entity</span> : <div><input 
                                                name="isExemptEntity" 
                                                defaultValue={(boItem.boFincenId === null || boItem.boFincenId === '') ? boItem.isExemptEntity : ""}
                                                onChange={(e) => handleChangeBOInput(e, boIndex)} 
                                                type="text" 
                                                placeholder="Exempt entity"
                                              /></div>
                                            </Col>
                                            <Col className="mt-2 mb-2"><span className='label'>Last Name (or legal entity name of Beneficial Owner)</span> : <div><input 
                                                name="boIndividualLastNameEntityLegalName" 
                                                defaultValue={boItem.boFincenId !== '' ? "" : boItem.boIndividualLastNameEntityLegalName}
                                                onChange={(e) => handleChangeBOInput(e, boIndex)} 
                                                type="text" 
                                              /></div>
                                            </Col>
                                            <Col className="mt-2 mb-2"><span className='label'>First name</span> : <div><input 
                                                name="boFirstName" 
                                                defaultValue={(boItem.boFincenId !== '' || boItem.isExemptEntity !== '') ? "" : boItem.boFirstName}
                                                onChange={(e) => handleChangeBOInput(e, boIndex)} 
                                                type="text" 
                                              /></div>
                                            </Col>
                                            <Col className="mt-2 mb-2"><span className='label'>Middle name</span> : <div><input 
                                                name="boMiddleName" 
                                                defaultValue={(boItem.boFincenId !== '' || boItem.isExemptEntity !== '') ? "" : boItem.boMiddleName}
                                                onChange={(e) => handleChangeBOInput(e, boIndex)} 
                                                type="text" 
                                              /></div>
                                            </Col>
                                            <Col className="mt-2 mb-2"><span className='label'>Suffix</span> : <div><input 
                                                name="boSuffix" 
                                                defaultValue={(boItem.boFincenId !== '' || boItem.isExemptEntity !== '') ? "" : boItem.boSuffix}
                                                onChange={(e) => handleChangeBOInput(e, boIndex)} 
                                                type="text" 
                                              /></div>
                                            </Col>
                                            <Col className="mt-2 mb-2"><span className='label'>Date of birth</span> : <div><input 
                                                name="boDateOfBirth" 
                                                defaultValue={(boItem.boFincenId !== '' || boItem.isExemptEntity !== '') ? "" : boItem.boDateOfBirth}
                                                onChange={(e) => handleChangeBOInput(e, boIndex)} 
                                                type="text" 
                                              /></div>
                                            </Col>
                                          </Row>
                                          <hr />
                                          <h3>Residential address</h3>
                                          <Row xs={4}>
                                            <Col className="mt-2 mb-2"><span className='label'>Country/Jurisdiction</span> : <div><input 
                                                name="boResidentialCountryJurisdiction" 
                                                defaultValue={(boItem.boFincenId !== '' || boItem.isExemptEntity !== '') ? "" : boItem.boResidentialCountryJurisdiction}
                                                onChange={(e) => handleChangeBOInput(e, boIndex)} 
                                                type="text" 
                                              /></div>
                                            </Col>
                                            <Col className="mt-2 mb-2"><span className='label'>Country/Jurisdiction Code</span> : <div><input 
                                                name="boResidentialCountryJurisdictionCode" 
                                                defaultValue={(boItem.boFincenId !== '' || boItem.isExemptEntity !== '') ? "" : boItem.boResidentialCountryJurisdictionCode}
                                                onChange={(e) => handleChangeBOInput(e, boIndex)} 
                                                type="text" 
                                              /></div>
                                            </Col>
                                            <Col className="mt-2 mb-2"><span className='label'>Address (number, street, and apt. or suite no.)</span> : <div><input 
                                                name="boResidentialAddress" 
                                                defaultValue={(boItem.boFincenId !== '' || boItem.isExemptEntity !== '') ? "" : boItem.boResidentialAddress}
                                                onChange={(e) => handleChangeBOInput(e, boIndex)} 
                                                type="text" 
                                              /></div>
                                            </Col>
                                            <Col className="mt-2 mb-2"><span className='label'>State</span> : <div><input 
                                                name="boResidentialState" 
                                                defaultValue={(boItem.boFincenId !== '' || boItem.isExemptEntity !== '') ? "" : boItem.boResidentialState}
                                                onChange={(e) => handleChangeBOInput(e, boIndex)} 
                                                type="text" 
                                              /></div>
                                            </Col>
                                            <Col className="mt-2 mb-2"><span className='label'>State Code</span> : <div><input 
                                                name="boResidentialStateCode" 
                                                defaultValue={(boItem.boFincenId !== '' || boItem.isExemptEntity !== '') ? "" : boItem.boResidentialStateCode}
                                                onChange={(e) => handleChangeBOInput(e, boIndex)} 
                                                type="text" 
                                              /></div>
                                            </Col>
                                            <Col className="mt-2 mb-2"><span className='label'>City</span> : <div><input 
                                                name="boResidentialCity" 
                                                defaultValue={(boItem.boFincenId !== '' || boItem.isExemptEntity !== '') ? "" : boItem.boResidentialCity}
                                                onChange={(e) => handleChangeBOInput(e, boIndex)} 
                                                type="text" 
                                              /></div>
                                            </Col>
                                            <Col className="mt-2 mb-2"><span className='label'>ZIP/Foreign postal code</span> : <div><input 
                                                name="boResidentialZipcode" 
                                                defaultValue={(boItem.boFincenId !== '' || boItem.isExemptEntity !== '') ? "" : boItem.boResidentialZipcode}
                                                onChange={(e) => handleChangeBOInput(e, boIndex)} 
                                                type="text" 
                                              /></div>
                                            </Col>
                                            <Col className="mt-2 mb-2"><span className='label'>Social security number (or similar number if non-U.S. person)</span> : <div><input 
                                                name="boSSN" 
                                                defaultValue={(boItem.boFincenId !== '' || boItem.isExemptEntity !== '') ? "" : boItem.boSSN}
                                                onChange={(e) => handleChangeBOInput(e, boIndex)} 
                                                type="text" 
                                              /></div>
                                            </Col>
                                          </Row>
                                          <hr />
                                          <h3>Form of identification and issuing jurisdiction</h3>
                                          <Row xs={4}>
                                            <Col className="mt-2 mb-2"><span className='label'>Identifying document type</span> : <div><input 
                                                name="boDocumentIdType" 
                                                defaultValue={(boItem.boFincenId !== '' || boItem.isExemptEntity !== '') ? "" : boItem.boDocumentIdType}
                                                onChange={(e) => handleChangeBOInput(e, boIndex)} 
                                                type="text" 
                                              /></div>
                                            </Col>
                                            <Col className="mt-2 mb-2"><span className='label'>Identifying document number</span> : <div><input 
                                                name="boDocumentIdNumber" 
                                                defaultValue={(boItem.boFincenId !== '' || boItem.isExemptEntity !== '') ? "" : boItem.boDocumentIdNumber}
                                                onChange={(e) => handleChangeBOInput(e, boIndex)} 
                                                type="text" 
                                              /></div>
                                            </Col>
                                          </Row>
                                          <hr />
                                          <h3>Identifying document issuing jurisdiction</h3>
                                          <Row xs={4}>
                                            <Col className="mt-2 mb-2"><span className='label'>a. Country/Jurisdiction</span> : <div><input 
                                                name="boDocumentIdIssuingCountryJurisdiction" 
                                                defaultValue={(boItem.boFincenId !== '' || boItem.isExemptEntity !== '') ? "" : boItem.boDocumentIdIssuingCountryJurisdiction}
                                                onChange={(e) => handleChangeBOInput(e, boIndex)} 
                                                type="text" 
                                              /></div>
                                            </Col>
                                            <Col className="mt-2 mb-2"><span className='label'>Country/Jurisdiction</span> : <div><input 
                                                name="boDocumentIdIssuingCountryJurisdictionCode" 
                                                defaultValue={(boItem.boFincenId !== '' || boItem.isExemptEntity !== '') ? "" : boItem.boDocumentIdIssuingCountryJurisdictionCode}
                                                onChange={(e) => handleChangeBOInput(e, boIndex)} 
                                                type="text" 
                                              /></div>
                                            </Col>
                                            <Col className="mt-2 mb-2"><span className='label'>b. State</span> : <div><input 
                                                name="boDocumentIdIssuingState" 
                                                defaultValue={(boItem.boFincenId !== '' || boItem.isExemptEntity !== '') ? "" : boItem.boDocumentIdIssuingState}
                                                onChange={(e) => handleChangeBOInput(e, boIndex)} 
                                                type="text" 
                                              /></div>
                                            </Col>
                                            <Col className="mt-2 mb-2"><span className='label'>State Code</span> : <div><input 
                                                name="boDocumentIdIssuingStateCode" 
                                                defaultValue={(boItem.boFincenId !== '' || boItem.isExemptEntity !== '') ? "" : boItem.boDocumentIdIssuingStateCode}
                                                onChange={(e) => handleChangeBOInput(e, boIndex)} 
                                                type="text" 
                                              /></div>
                                            </Col>
                                            <Col className="mt-2 mb-2"><span className='label'>c. Local/Tribal</span> : <div><input 
                                                name="boDocumentIdIssuingLocalTribal" 
                                                defaultValue={(boItem.boFincenId !== '' || boItem.isExemptEntity !== '') ? "" : boItem.boDocumentIdIssuingLocalTribal}
                                                onChange={(e) => handleChangeBOInput(e, boIndex)} 
                                                type="text" 
                                              /></div>
                                            </Col>
                                            <Col className="mt-2 mb-2"><span className='label'>Local/Tribal</span> : <div><input 
                                                name="boDocumentIdIssuingLocalTribalCode" 
                                                defaultValue={(boItem.boFincenId !== '' || boItem.isExemptEntity !== '') ? "" : boItem.boDocumentIdIssuingLocalTribalCode}
                                                onChange={(e) => handleChangeBOInput(e, boIndex)} 
                                                type="text" 
                                              /></div>
                                            </Col>
                                            <Col className="mt-2 mb-2">
                                              <span className='label'>d. Other local/Tribal description</span> : <div><input 
                                                name="boDocumentIdIssuingLocalOtherTribe" 
                                                defaultValue={(boItem.boFincenId !== '' || boItem.isExemptEntity !== '') ? "" : boItem.boDocumentIdIssuingLocalOtherTribe}
                                                onChange={(e) => handleChangeBOInput(e, boIndex)} 
                                                type="text" 
                                              /></div>
                                            </Col>
                                          </Row>
                                          <p className='mb-0 mt-2'><b>Identifying document</b></p>
                                          <Row>
                                            <Col className="mt-2 mb-2"><span className='label'>Identifying document image</span> : {(boItem.boFincenId !== '' || boItem.isExemptEntity !== '') ? "" : boItem.boIdentifyingDocumentImageBase64 && <img src={boItem.boIdentifyingDocumentImageBase64.startsWith('data:image') ? boItem.boIdentifyingDocumentImageBase64 : 'data:image/jpeg;base64,'+boItem.boIdentifyingDocumentImageBase64} height="80" alt="Image" type="button" onClick={() => {setImageData(boItem.boIdentifyingDocumentImageBase64.startsWith('data:image') ? boItem.boIdentifyingDocumentImageBase64 : 'data:image/jpeg;base64,'+boItem.boIdentifyingDocumentImageBase64); setShowImage(true);}} />}</Col>
                                          </Row>
                                        </Accordion.Body>
                                      </Accordion.Item>)
                                    })}
                                  </Accordion>
                                  : entityItem.entity_data_type.companyApplicantInformation === 1 ? entityItem.entity_data && <Accordion>
                                  {JSON.parse(entityItem.entity_data).map((companyItem, companyIndex) => (
                                    <Accordion.Item
                                      eventKey={`companyApplicant-${companyIndex}`}
                                      className="mb-3"
                                      key={`companyApplicant-${companyIndex}`}
                                    >
                                      <Accordion.Header>Company Applicant #{companyIndex + 1}</Accordion.Header>
                                      <Accordion.Body>
                                        {companyItem.applicantIdentifyingDocumentImageBase64 && (
                                          <div className="mt-2">
                                            <p className="mb-0"><b>Identifying Document</b></p>
                                              <span className='label'>Identifying document image :</span>
                                              {companyItem.applicantFincenId !== '' ? "" : companyItem.applicantIdentifyingDocumentImageBase64 && <img src={companyItem.applicantIdentifyingDocumentImageBase64.startsWith('data:image') ? companyItem.applicantIdentifyingDocumentImageBase64 : 'data:image/jpeg;base64,'+companyItem.applicantIdentifyingDocumentImageBase64} height="80" alt="Image" type="button" onClick={() => {setImageData(companyItem.applicantIdentifyingDocumentImageBase64.startsWith('data:image') ? companyItem.applicantIdentifyingDocumentImageBase64 : 'data:image/jpeg;base64,'+companyItem.applicantIdentifyingDocumentImageBase64); setShowImage(true);}} />}
                                          </div>
                                          )}
                                        <div>
                                          <div><span className="label">FinCEN ID</span> : {companyItem.applicantFincenId || ""}
                                          <input 
                                            name="applicantFincenId" 
                                            defaultValue={companyItem.applicantFincenId} 
                                          onChange={(e) => handleChangeCAInput(e, companyIndex)} 
                                            type="text" 
                                          />
                                          </div>
                                          <div><span className="label">Individual's last name</span> :
                                          <input 
                                            name="applicantIndividualLastName" 
                                            defaultValue={companyItem.applicantFincenId !== '' ? '' : companyItem.applicantIndividualLastName} 
                                          onChange={(e) => handleChangeCAInput(e, companyIndex)} 
                                            type="text" 
                                          />
                                          </div>
                                          <div><span className="label">First name</span> : 
                                          <input 
                                            name="applicantFirstName" 
                                            defaultValue={companyItem.applicantFincenId !== '' ? '' : companyItem.applicantFirstName} 
                                          onChange={(e) => handleChangeCAInput(e, companyIndex)} 
                                            type="text" 
                                          />
                                          </div>
                                          <div><span className="label">Middle name</span> :
                                          <input 
                                            name="applicantMiddleName" 
                                            defaultValue={companyItem.applicantFincenId !== '' ? '' : companyItem.applicantMiddleName} 
                                          onChange={(e) => handleChangeCAInput(e, companyIndex)} 
                                            type="text" 
                                          />
                                          </div>
                                          <div><span className="label">Suffix</span> :
                                          <input 
                                            name="applicantSuffix" 
                                            defaultValue={companyItem.applicantFincenId !== '' ? '' : companyItem.applicantSuffix} 
                                          onChange={(e) => handleChangeCAInput(e, companyIndex)}  
                                            type="text" 
                                          />
                                          </div>
                                          <div><span className="label">Date of birth</span> :
                                          <input 
                                            name="applicantDateOfBirth" 
                                            defaultValue={companyItem.applicantFincenId !== '' ? '' : companyItem.applicantDateOfBirth} 
                                          onChange={(e) => handleChangeCAInput(e, companyIndex)} 
                                            type="text" 
                                          /></div>
                                          <div><span className="label">Job Title</span> :
                                          <input 
                                            name="applicantTitle" 
                                            defaultValue={companyItem.applicantFincenId !== '' ? '' : companyItem.applicantTitle} 
                                          onChange={(e) => handleChangeCAInput(e, companyIndex)} 
                                            type="text" 
                                          />
                                          </div>
                                        </div>
                                        <hr />
                                        <h6>Current Address</h6>
                                        <div>
                                          <div><span className="label">Address Type</span> :
                                          <input 
                                            name="applicantAddressType" 
                                            defaultValue={companyItem.applicantFincenId !== '' ? '' : companyItem.applicantAddressType} 
                                          onChange={(e) => handleChangeCAInput(e, companyIndex)} 
                                            type="text" 
                                          />
                                          </div>
                                          <div><span className="label">Country/Jurisdiction</span> :
                                          <input 
                                            name="applicantCurrentCountryJurisdiction" 
                                            defaultValue={companyItem.applicantFincenId !== '' ? '' : companyItem.applicantCurrentCountryJurisdiction} 
                                          onChange={(e) => handleChangeCAInput(e, companyIndex)} 
                                            type="text" 
                                          />
                                          </div>
                                          <div><span className="label">Address (number, street, and apt. or suite no.)</span> :
                                          <input 
                                            name="applicantCurrentAddress" 
                                            defaultValue={companyItem.applicantFincenId !== '' ? '' : companyItem.applicantCurrentAddress} 
                                          onChange={(e) => handleChangeCAInput(e, companyIndex)} 
                                            type="text" 
                                          />
                                          </div>
                                          <div><span className="label">State</span> :
                                          <input 
                                            name="applicantCurrentState" 
                                            defaultValue={companyItem.applicantFincenId !== '' ? '' : companyItem.applicantCurrentState} 
                                          onChange={(e) => handleChangeCAInput(e, companyIndex)} 
                                            type="text" 
                                          />
                                          </div>
                                          <div><span className="label">City</span> :
                                          <input 
                                            name="applicantCurrentCity" 
                                            defaultValue={companyItem.applicantFincenId !== '' ? '' : companyItem.applicantCurrentCity} 
                                          onChange={(e) => handleChangeCAInput(e, companyIndex)} 
                                            type="text" 
                                          />
                                          </div>
                                          <div><span className="label">ZIP/Foreign postal code</span> :
                                          <input 
                                            name="applicantCurrentZipcode" 
                                            defaultValue={companyItem.applicantFincenId !== '' ? '' : companyItem.applicantCurrentZipcode} 
                                          onChange={(e) => handleChangeCAInput(e, companyIndex)} 
                                            type="text" 
                                          />
                                          </div>
                                        </div>
                                        <hr />
                                        <h6>Form of identification and issuing jurisdiction</h6>
                                        <div>
                                          <div><span className="label">Identifying document type </span> : 
                                          <input 
                                            name="applicantDocumentIdType" 
                                            defaultValue={companyItem.applicantFincenId !== '' ? '' : companyItem.applicantDocumentIdType} 
                                          onChange={(e) => handleChangeCAInput(e, companyIndex)} 
                                            type="text" 
                                          />
                                          </div>
                                          <div><span className="label">Identifying document number</span> :
                                          <input 
                                            name="applicantDocumentIdNumber" 
                                            defaultValue={companyItem.applicantFincenId !== '' ? '' : companyItem.applicantDocumentIdNumber} 
                                          onChange={(e) => handleChangeCAInput(e, companyIndex)} 
                                            type="text" 
                                          />
                                          </div>
                                        </div>
                                        <hr />
                                        <h6>Identifying document issuing jurisdiction</h6>
                                        <div>
                                          <div><span className="label">a. Country/Jurisdiction</span> :
                                          <input 
                                            name="applicantDocumentIdIssuingCountryJurisdiction" 
                                            defaultValue={companyItem.applicantFincenId !== '' ? '' : companyItem.applicantDocumentIdIssuingCountryJurisdiction} 
                                          onChange={(e) => handleChangeCAInput(e, companyIndex)} 
                                            type="text" 
                                          />
                                          </div>
                                          <div><span className="label">b. State</span> :
                                          <input 
                                            name="applicantDocumentIdIssuingState" 
                                            defaultValue={companyItem.applicantFincenId !== '' ? '' : companyItem.applicantDocumentIdIssuingState} 
                                          onChange={(e) => handleChangeCAInput(e, companyIndex)} 
                                            type="text" 
                                          />
                                          </div>
                                          <div><span className="label">c. Local/Tribal</span> :
                                          <input 
                                            name="applicantDocumentIdIssuingLocalTribal" 
                                            defaultValue={companyItem.applicantFincenId !== '' ? '' : companyItem.applicantDocumentIdIssuingLocalTribal} 
                                          onChange={(e) => handleChangeCAInput(e, companyIndex)} 
                                            type="text" 
                                          />
                                          </div>
                                          <div><span className="label">d. Other local/Tribal description</span> :
                                          <input 
                                            name="applicantDocumentIdIssuingLocalOtherTribe" 
                                            defaultValue={companyItem.applicantFincenId !== '' ? '' : companyItem.applicantDocumentIdIssuingLocalOtherTribe} 
                                          onChange={(e) => handleChangeCAInput(e, companyIndex)} 
                                            type="text" 
                                          />
                                          </div>
                                        </div>
                                      </Accordion.Body>
                                    </Accordion.Item>
                                  ))}
                                </Accordion>
                                :
                                  <>
                                    {//type = multiselect
                                      entityItem.entity_data_type.type === 'multiselect' ? <>
                                      {entityItem.entity_data_type.options && entityItem.entity_data_type.options.map((options, optionsIndex) =>
                                        <Form.Check 
                                          key={optionsIndex} 
                                          type="checkbox" 
                                          name={entityItem.entity_name} 
                                          value={options} 
                                          onChange={handleDataChange} 
                                          id={options} 
                                          label={<span dangerouslySetInnerHTML={{__html: options}} />} 
                                          defaultChecked={inputs[entityItem.entity_name] ? inputs[entityItem.entity_name].includes(options) : entityItem.entity_data ? entityItem.entity_data.includes(options) : false} 
                                          readOnly={!userPrivilegedEditApplication} 
                                        />
                                      )}
                                    </> : <input 
                                      type="text" 
                                      className='form-data' 
                                      name={entityItem.entity_name} 
                                      defaultValue={entityItem.entity_data} 
                                      onChange={handleDataChange} 
                                      readOnly={!userPrivilegedEditApplication || entityItem.entity_name === "typeOfFiling" || entityItem.entity_name === "primaryDocument" || entityItem.entity_name === "IssuanceDate" || entityItem.entity_name === "passportNumber" || entityItem.entity_name === "expirationDate" || entityItem.entity_name === "countryOfIssuance" || entityItem.entity_name === "captureSelfiePassportChecks" || entityItem.entity_name === "captureSelfiePassport"} 
                                    />
                                  }
                                  </>}
                                </>
                              }
                            </div>
                          </>}
                        </Col>}
                      </>)
                    })}
                  </Row>}
                </div>)
              })}
            </Container>
          </section>)
        })}
        <section className='pt-5 pb-5 bdr-btm'>{/* buttons */}
          <Container fluid>
            <Button variant="transparent" className='w-auto' onClick={() => navigate("/application-details?" + applicationID)}>Cancel</Button>
            <Button variant="primary" onClick={handleSaveData} className='ms-3 px-5'>Save</Button>
            {/* {kycDetails.kyc_rejected === 1 && kycDetails.kyc_attempt_exceeded === 1 && kycDetails.kyc_approved_manually !== 1 && <Button variant="primary" className='ms-3 w-auto' onClick={handleShowConfirmationOverrideKyc} disabled={!userPrivilegedManualOverrideKyc}>Manual Override KYC</Button>}
            {kycDetails.kyc_approved_manually === 1 && <span className='ms-3 fs-5'><b>KYC approved manually!</b></span>} */}
            <span className='float-end'><span className='label'>Last Updated:</span> {lastUpdated}</span>

            <Modal show={showConfirmationOverrideKyc} onHide={handleCloseConfirmationOverrideKyc} backdrop="static"keyboard={false} centered className='content-text'>
              <Modal.Header closeButton>
                <Modal.Title><img src={require('./../assets/images/confirmation-icon.png')} alt="Confirmation Icon" className='me-3' />Confirmation</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>Are you sure you want to Manually Override KYC for</p>
                <p>{appData.firstName} {appData.middleName ? appData.middleName+' ' : null}{appData.lastName}</p>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="transparent" onClick={handleCloseConfirmationOverrideKyc}>No</Button>
                <Button variant="primary" className='ms-3' onClick={handleOverrideKyc}>Yes</Button>
              </Modal.Footer>
            </Modal>
          </Container>
        </section>
      </main>

      <Modal className='show-picture' show={showImage} onHide={handleCloseImage} backdrop="static" keyboard={false} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          {imageData && <img src={imageData} alt="Image" className='img-fluid' />}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ApplicationDetailsEdit;
