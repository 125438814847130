import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Form, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import UserService from "../../services/UserService.js";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Payment from "./Payment.jsx";
import logoSvg from './../../assets/images/logo.svg';
import {loadStripe} from '@stripe/stripe-js';

const serverConfig = require("../../config/server.js");
const appConfig = require("../../config/config.js");

const UserRegistration = ({addCredits, org}) => {
  console.log(addCredits);
  const searchParams = new URLSearchParams(window.location.search);
  
  const paymentId = searchParams.get('payment_session_id') ? searchParams.get('payment_session_id') : "";
  const cancelPaymentId = searchParams.get('cancel_payment_session_id') ? searchParams.get('cancel_payment_session_id') : "";

  const org_slug = addCredits ? org : searchParams.get("id");//if coming from addCredits button click
  console.log(org_slug);

  const [noOfApplications, setNoOfApplications] = useState(0);
  const [amount, setAmount] = useState(0);
  const [itemSlug, setItemSlug] = useState("");
  const [applicationNumList, setApplicationNumList] = useState([]);

  useEffect(() => {
    console.log(applicationNumList);
    if(applicationNumList.length > 0){
      console.log(applicationNumList[0].no_of_applications, applicationNumList[0].amount);
      setNoOfApplications(applicationNumList[0].no_of_applications);
      setAmount(applicationNumList[0].amount);
      setItemSlug(applicationNumList[0].slug);
    }
  }, [applicationNumList]);

  useEffect(()=>{
    if(paymentId){
      axios.post(serverConfig.fapi_base_url + "check_payment_status", {
        //payment_session_id : paymentId
        stripe_session_slug : sessionStorage.getItem("stripeSessionSlug"),
        name : sessionStorage.getItem("name"),
        org : sessionStorage.getItem("org"),
        item_slug : sessionStorage.getItem("itemSlug"),
      }).then(response=> {
        console.log(response, 'check_payment_status');
        if(response.data.status === 200) {
          setResponseMessage(<div className="txt-green">Payment successful. Please check your email to continue.</div>);
          setFormIsDisabled(true);
          sessionStorage.removeItem("stripeSessionSlug");
          sessionStorage.removeItem("name");
          sessionStorage.removeItem("itemSlug");
        } /* else if(response.data.status === 400){
          setResponseMessage(<div><span className="txt-red">{response.data.message}</span> <button>Retry</button></div>);
          setFormIsDisabled(false);
          sessionStorage.removeItem("stripeSessionSlug");
          sessionStorage.removeItem("name");
          sessionStorage.removeItem("itemSlug");
        } */ else {
          var link;
          if(sessionStorage.getItem("org") === null || sessionStorage.getItem("org") === "null"){
            link="/register";
          } else {
            link="/register?id="+sessionStorage.getItem("org");
          }
          console.log(sessionStorage.getItem("org"), window.location.origin, window.location.origin+"/register?id="+sessionStorage.getItem("org"));
          setResponseMessage(<div className="d-flex align-items-center gap-3"><span className="txt-red">{response.data.message}</span> <Button variant="link" className="p-0" onClick={() => {navigate(link); setResponseMessage(""); setFormIsDisabled(false);}}>Try again</Button></div>);
          setFormIsDisabled(true);
          sessionStorage.removeItem("stripeSessionSlug");
          sessionStorage.removeItem("name");
          sessionStorage.removeItem("itemSlug");
        }
      }).catch(err=> {
        console.log(err);
      })
    }
  }, [paymentId]);

  useEffect(()=>{
    if(cancelPaymentId){
      alert("Payment cancelled");
    }
  }, [cancelPaymentId]);

  const [logoBase64Text, setLogoBase64Text] = useState('');
  const navigate = useNavigate();

  //const [isAuthorizedSigner, setIsAuthorizedSigner] = useState(false);
  const [isAuthorizedSigner, setIsAuthorizedSigner] = useState(true);
  const [inputs, setInputs] = useState({});
  const [formIsDisabled, setFormIsDisabled] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value}))
  }

  const handleRegister = (event) => {
    event.preventDefault();
    console.log(inputs);
    axios.post(serverConfig.api_base_url + "user_signup",{name: inputs.name, email_id: inputs.email_id, org: org_slug})
    .then(response=>{
      console.log('user_signup response', response);
      if(response.data.status === 200) {
        setResponseMessage("Please check your email to proceed with the registration process");
        setInputs({});
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  useEffect(() => {
    axios.post(serverConfig.api_base_url + "get_logo_image",{domainName:window.location.host})
    .then(response=>{
      console.log('get_logo_image response', response);
      if(response.data.status === 200) {
        setLogoBase64Text(response.data.logoBase64);
      } else if(response.data.status >= 400 && response.data.status <= 499){
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));

    axios.post(serverConfig.api_base_url + "org_credit_plan_get", {org: org_slug})
    .then(response=>{
      console.log('org_credit_plan_get', response);
      if(response.data.status === 200) {
        if(response.data.items !== null){
          setApplicationNumList(response.data.items);
        }
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }, []);

  const [showAuthorizedSignerPopup, setShowAuthorizedSignerPopup] = useState(false);
  const handleShowAuthorizedSignerPopup = () => {
    setShowAuthorizedSignerPopup(true);
  }
  const handleCloseAuthorizedSignerPopup = () => {
    setShowAuthorizedSignerPopup(false);
    //setInputs({});
  }

  const handleSelectNoOfApplications = (event) => {
    setNoOfApplications(event.target.value);
    console.log(event.target.options[event.target.selectedIndex].getAttribute('amount'));
    setAmount(event.target.options[event.target.selectedIndex].getAttribute('amount'));
    setItemSlug(event.target.options[event.target.selectedIndex].getAttribute('slug'));
  }

  const handleProceedToPayment = async (e) => {
    e.preventDefault();
    console.log("noOfApplications-", noOfApplications, "amount-", amount, "itemSlug-", itemSlug, "org_slug-", org_slug, "email-", inputs.email, "addCredits-", addCredits);

    const stripePromise = loadStripe(appConfig.stripe_key);

    const response = await fetch(
      serverConfig.fapi_base_url + "create_checkout_session",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          item_slug: itemSlug,
          org_slug: org_slug,
          customer_email: addCredits ? UserService.getUsername() : inputs.email,
          isNotRegister: addCredits ? addCredits : ""
        }),
      }
    );

    const { sessionId, stripe_session_slug } = await response.json();
    sessionStorage.setItem("stripeSessionSlug", stripe_session_slug);
    sessionStorage.setItem("name", addCredits ? UserService.getWelcomeName() : inputs.name);
    sessionStorage.setItem("org", org_slug);
    sessionStorage.setItem("itemSlug", itemSlug);
    const stripe = await stripePromise;
    const { error } = await stripe.redirectToCheckout({ sessionId });

    if (error) {
      console.error("Error redirecting to Checkout:", error);
      setResponseMessage(<div className="txt-red">Error redirecting to Checkout</div>);
    }
  }

  return (
    <>
      <div className="login">
        <div className="login-container">
          {/* <div className="login-left p-4 d-inline-flex flex-column justify-content-center" style={{width:"530px"}}>
            <img src={require("../../assets/images/login.jpg")} alt="Login Banner" height={300} width={500} style={{height: "300px", objectPosition: "top"}} />
            <div>
              <h1 style={{fontSize: "18px"}} className="mt-3">Company Applicant / Authorized Signer Registration</h1>
              <p><span className="txt-red">*</span>An authorized signer for Manatoko BOIR refers to the individual who is responsible for submitting the BOI report on behalf of the company. This person is someone with authority to act for the company, such as a company officer or authorized representative.</p>
              <Button variant="primary" className="w-100" type="button" onClick={handleShowAuthorizedSignerPopup}>Register</Button>
              <div className='redirect-signup justify-content-center'>
                <div className='redirect-text'>Already Registered?</div> <button onClick={()=>{ navigate("/customer")} } className='login-btn'>LOG IN</button>
              </div>
              <Modal show={showAuthorizedSignerPopup} onHide={handleCloseAuthorizedSignerPopup} centered keyboard={false}>
                <Modal.Header closeButton>
                  <Modal.Title>Authorized Signer</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form.Label>Are you the authorized signer for your business? <span className="txt-red">*</span></Form.Label>
                  <div className="d-flex mb-1">
                    <Form.Check inline type="radio" name="isAuthorizedSigner" value={"Yes" || ""} onChange={(e) => {setInputs({...inputs, isAuthorizedSigner: e.target.value}); setIsAuthorizedSigner(true)}} id="isAuthorizedSignerYes" label="Yes" required defaultChecked={inputs.isAuthorizedSigner === "Yes"} />
                    <Form.Check inline type="radio" name="isAuthorizedSigner" value={"No" || ""} onChange={(e) => {setInputs({...inputs, isAuthorizedSigner: e.target.value}); setIsAuthorizedSigner(false)}} id="isAuthorizedSignerNo" label="No" required defaultChecked={inputs.isAuthorizedSigner === "No"} />
                  </div>
                  {inputs.isAuthorizedSigner === "Yes" && <span className="txt-green">Please close this popup and proceed with the payment.</span>}
                  {inputs.isAuthorizedSigner === "No" && <span className="txt-red">Only the authorized signer can fill this form. Please forward the invite email you received to the authorized signer.</span>}
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
              </Modal>
            </div>
          </div> */}
          <div className="form-container pt-4">
            <div className="signup-form-wrapper">
              <div className="kc-content">
                <div className="kc-content-wrapper">
                  <div className="kc-form">
                    <div className="kc-form-image">
                      <img
                        src={logoSvg}
                        alt="Logo"
                        className="img-fluid" width="300"
                      />
                    </div>
                    <div className="text-center">
                      <h1 style={{fontSize: "18px"}}>Manatoko BOIR Purchase Plan</h1>
                      {/* <ul className="text-start d-inline-block">
                        <li>Answer Beneficial Ownership requirements</li>
                        <li>File BOI information with FinCEN</li>
                        <li>Manage and update your data</li>
                      </ul> */}
                      {!addCredits && <div className='redirect-signup justify-content-center mb-4'>
                        <div className='redirect-text'>Already Registered?</div> <button onClick={()=>{ navigate("/customer")} } className='login-btn'>LOG IN</button>
                      </div>}
                    </div>
                    <Form onSubmit={handleProceedToPayment}>
                      <fieldset disabled={formIsDisabled}>
                        <Form.Group className="mb-2">
                          <Form.Label>Select number of companies <span className='txt-red'>*</span></Form.Label>
                          <Form.Select value={noOfApplications} onChange={handleSelectNoOfApplications} required>
                            {applicationNumList.length > 0 && 
                              applicationNumList.map((item) => <option value={item.no_of_applications} amount={item.amount} slug={item.slug}>{item.label}</option>)
                            }
                          </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-2">
                          <Form.Label className='mb-0'>
                            Name of Authorized Signer <span className='txt-red'>*</span>
                            <OverlayTrigger placement="top" overlay={<Tooltip>This is the person who is Authorized Signer of the company</Tooltip>}>
                              <span className="info"><i>i</i></span>
                            </OverlayTrigger>
                          </Form.Label>
                          <Form.Control type="text" value={addCredits ? UserService.getWelcomeName() : inputs.name} name="name" onChange={(e) => setInputs({...inputs, name: e.target.value})} required readOnly={addCredits} />
                        </Form.Group>
                        <Form.Group className="mb-2">
                          <Form.Label className='mb-0'>Email of Authorized Signer <span className='txt-red'>*</span></Form.Label>
                          <Form.Control type="email" value={addCredits ? UserService.getUsername() : inputs.email} name="email" onChange={(e) => setInputs({...inputs, email: e.target.value})} required readOnly={addCredits} />
                        </Form.Group>

                        <div className="subscription-box p-4 mt-2">
                          <h5>Amount</h5>
                          <p>$ {amount}</p>
                          <div className="payment-btn-container mt-2"><Button type="submit" variant="primary" className="w-100" id="submit">Proceed to Payment</Button></div>
                        </div>
                      </fieldset>
                    </Form>

                    {responseMessage && <div className="mt-4">{responseMessage}</div>}
                    
                    {/* <Payment orgSlug={org_slug} isAuthorizedSigner={isAuthorizedSigner} setIsAuthorizedSigner={setIsAuthorizedSigner} setApplicationNumList={setApplicationNumList} noOfApplications={noOfApplications} amount={amount} /> */}
                    {/* <Form onSubmit={handleRegister}>
                      <div className="flex flex-col">
                        <Form.Group>
                          <Form.Control
                            name="name"
                            className="signup-input"
                            type="text"
                            placeholder="Enter your name"
                            value={inputs.name || ""}
                            onChange={handleChange}
                            required
                          />
                        </Form.Group>
                      </div>
                      <div className="flex flex-col mt-3">
                        <Form.Group>
                          <Form.Control
                            name="email_id"
                            className="signup-input"
                            type="email"
                            placeholder="Enter your email"
                            value={inputs.email_id || ""}
                            onChange={handleChange}
                            required
                          />
                        </Form.Group>
                      </div>
                      <div className="kc-form-buttons mt-4 mb-4">
                        <Button
                          variant="primary"
                          className="signup-submit-btn"
                          type="submit"
                        >
                          Register
                        </Button>
                      </div>
                      <div className='redirect-signup'>
                                <div className='redirect-text'>Already Registered?</div> <button onClick={()=>{ navigate("/customer")} } className='login-btn'>LOG IN</button>
                                </div>
                      <p className="txt-green">{responseMessage ? responseMessage : <>&nbsp;</>}</p>
                    </Form> */}
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="disclaimer">
            This computer network belongs to Amberoon Inc. and may be used only by Amberoon employees and Customers only for work-related purposes. Amberoon Inc reserves the right to monitor use of this network to ensure network security and to respond to specific allegations of user misuse. Use of this network shall constitute consent to monitoring for such purposes. In addition, the Amberoon Inc reserves the right to consent to a valid law enforcement request to search the network for evidence of a crime stored within the network.
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default UserRegistration;
