import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { Button, Form, Accordion } from 'react-bootstrap';
import axios from "axios";
import Autocomplete from "react-google-autocomplete";
const serverConfig = require("../config/server.js");

const BeneficialOwner = forwardRef(({handleShowInfoModal, inputs, setInputs, handleOptions, countryLookup, localTribalLookup, groupData}, ref) => {

  const emptyBOJson = {
    boSSN: "",
    boSuffix: "",
    boFincenId: "",
    boFirstName: "",
    boMiddleName: "",
    boDateOfBirth: "",
    isExemptEntity: "",
    boDocumentIdType: "",
    boResidentialCity: "",
    boDocumentIdNumber: "",
    boResidentialState: "",
    boResidentialAddress: "",
    boResidentialZipcode: "",
    boResidentialStateCode: "",
    boDocumentIdIssuingState: "",
    boIdentifyingDocumentImage: "",
    isParentGuardianInformation: "",
    boDocumentIdIssuingStateCode: "",
    boDocumentIdIssuingLocalTribal: "",
    boIdentifyingDocumentImageBase64: "",
    boResidentialCountryJurisdiction: "",
    boDocumentIdIssuingLocalOtherTribe: "",
    boDocumentIdIssuingLocalTribalCode: "",
    boIndividualLastNameEntityLegalName: "",
    boResidentialCountryJurisdictionCode: "",
    boDocumentIdIssuingCountryJurisdiction: "",
    boDocumentIdIssuingCountryJurisdictionCode: ""
  }

  const [beneficialOwner, setBeneficialOwner] = useState([emptyBOJson]);
  const [stateLookup, setStateLookup] = useState([]);
  const [cityLookup, setCityLookup] = useState([]);
  const [activeKey, setActiveKey] = useState("0");

  useEffect(() => {
    //console.log(sessionStorage.getItem('token'));
    console.log(inputs, groupData);
    let boi = groupData.group_sections.filter(section => section.section_name === "beneficial_owner_information");
    console.log(boi);
    if(boi.length > 0){
      let bo = boi[0].section_entities.filter(entities => entities.entity_name === "boInformation");
      console.log(JSON.parse(bo[0].entity_data));
      if(bo[0].entity_data){
        setBeneficialOwner(JSON.parse(bo[0].entity_data));
      } else {
        setBeneficialOwner([emptyBOJson]);
        setInputs({...inputs, boInformation: [emptyBOJson]});
      }
    }
  }, [groupData]);

  useImperativeHandle(ref, () => ({
    handleAddBeneficialOwner(){
      setBeneficialOwner([...beneficialOwner, emptyBOJson]);
      var id=beneficialOwner.length+1;
      setTimeout(() => {
        setActiveKey(`${beneficialOwner.length}`);
        setTimeout(() => {
          window.scrollTo(0, document.getElementById('bo'+id)?.offsetTop);
        }, 1000);
      }, );
    }
  }));

  /* const handleAddBeneficialOwner = () => {
    setBeneficialOwner([...beneficialOwner, emptyBOJson]);
  } */

  const handleRemoveBeneficialOwner = (event, index) => {
    console.log(index);
    let arr = beneficialOwner;
    console.log(beneficialOwner);

    if (index > -1) { // only splice array when item is found
      arr.splice(index, 1); // 2nd parameter means remove one item only
    }

    console.log(arr);
    setBeneficialOwner([]);
    setTimeout(() => {
      console.log(arr);
      setBeneficialOwner(arr);
      setInputs({...inputs, boInformation: arr});
      if(beneficialOwner.length === 0){
        setBeneficialOwner([""]);
        setInputs({...inputs, boInformation: [""]});
      }
    },);
  }

  const handleChangeBOInput = (event, index, entityDataType) => {
    const name = event.target.name;
    let value = event.target.value;
    console.log(value, entityDataType);
  
    console.log(beneficialOwner);
    console.log(...beneficialOwner);

    const newData={...inputs};

    if(entityDataType === "country" || entityDataType === "state" || entityDataType === "local_tribal"){
      let options = document.querySelectorAll('#'+name+(index+1)+' option');
      console.log("1", options);
      for(var i = 0; i < options.length; i++) {
        var option = options[i];
        if(option.innerText === value) {
          console.log(option.getAttribute('data-value'));
          beneficialOwner[index][name+'Code'] = option.getAttribute('data-value');

          if(name === "boResidentialCountryJurisdiction"){
            if(value === "American Samoa" || value === "Guam" || value === "Marshall Islands" || value === "Micronesia" || value === "Northern Mariana Islands" || value === "Palau" || value === "Puerto Rico" || value === "Virgin Islands (US)"){
              beneficialOwner[index]['boResidentialState'] = value;
              beneficialOwner[index]['boResidentialStateCode'] = option.getAttribute('data-value');
            }
          }
          if(name === "boDocumentIdIssuingCountryJurisdiction"){
            if(value === "American Samoa" || value === "Guam" || value === "Marshall Islands" || value === "Micronesia" || value === "Northern Mariana Islands" || value === "Palau" || value === "Puerto Rico" || value === "Virgin Islands (US)"){
              beneficialOwner[index]['boDocumentIdIssuingState'] = value;
              beneficialOwner[index]['boDocumentIdIssuingStateCode'] = option.getAttribute('data-value');
            }
          }

          break;
        }
      }

      if(entityDataType === "country"){
        axios.post(serverConfig.api_base_url + "get_lookup_values",{entity_name:"state", entity_parent_value:value})
        .then(response=>{
          console.log(response);
          if(response.data.data !== null){
            setStateLookup(response.data.data.sort((a, b) => a.value.localeCompare(b.value)));
          }
        })
        .catch(err=>console.log("error is",err));
      }
  
      if(entityDataType === "state"){
        axios.post(serverConfig.api_base_url + "get_lookup_values",{entity_name:"city", entity_parent_value:value})
        .then(response=>{
          console.log(response);
          setCityLookup(response.data.data.sort((a, b) => a.value.localeCompare(b.value)));
        })
        .catch(err=>console.log("error is",err));
      }
    }

    if(name === "boDocumentIdType"){
      if(value === "U.S. passport"){
        beneficialOwner[index]['boDocumentIdIssuingCountryJurisdiction'] = "United States";
        beneficialOwner[index]['boDocumentIdIssuingCountryJurisdictionCode'] = "US";
      }
    }

    if(event.target.type === 'checkbox'){
      if(event.target.checked){
        beneficialOwner[index][name] = value;
      } else {
        beneficialOwner[index][name] = "";
      }
    } else if(event.target.type === 'file'){
      var extension = value.split('.').pop();
      if(event.target.accept === '.pdf, .jpeg, .jpg, .png'){
        if(extension=='pdf' || extension=='jpeg' || extension=='jpg' || extension=='png'){
          console.log("File type matched");
          var file = document.querySelector('#'+event.target.id).files[0];
          console.log(file.size);
          let fileSizeInMB = file.size  / 1048576;
          console.log(fileSizeInMB);
          if(file !== undefined) {
            let filename = file.name;
            var fileData;
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
              fileData = reader.result;
              if(fileSizeInMB <= 4){
                beneficialOwner[index][name] = filename;
                beneficialOwner[index][name+'Base64'] = fileData;
              } else {
                alert('File size exceeds maximum limit of 4MB. Please try again.');
                event.target.value = '';
              }
            };
            reader.onerror = function (error) {
              console.log('Error: ', error);
            };
          }
        } else {
          alert("Only .pdf, .jpeg, .jpg, .png file is allowed");
          event.target.value = null;
        }
      }
    } else {
      beneficialOwner[index][name] = value;
    }

    setBeneficialOwner(beneficialOwner);
    console.log(beneficialOwner);
    setBeneficialOwner((stateBeneficialOwner) => {
      console.log(stateBeneficialOwner);
      newData['boInformation']=stateBeneficialOwner;
      return stateBeneficialOwner;
    });

    setInputs(newData);
    console.log(newData, inputs);
  }

  const handleDelete = (event) => {
    console.log('handleDelete');
    var key = event.keyCode || event.charCode;
    let custDataType = event.target.dataset.customDatatype;

    if( key == 8 || key == 46 ){
      if(event.target.value === ''){
        //setInputs(values => ({...values, [event.target.name]: event.target.value}))
      }
    } else if(!(key >= 48 && key <=57) && key!==9 && key!==13 && custDataType === "amount"){
      event.preventDefault();
    }

    if(event.target.type==='number'){
      if(["e", "E", "+", "-"].includes(event.key)){
        event.preventDefault();
      }
    }
  }

  //auto populate address
  const handlePlaceSelectedBO = (place, index) => {
    const Address = {};
    console.log(inputs, 'qq', beneficialOwner);
    console.log({...inputs});
    const newData={...inputs}
    console.log(newData);
    console.log(place);
    let placeArr = place.address_components;
    console.log(placeArr);
    let country = placeArr.filter(el => el.types.includes('country'));
    if(country.length > 0){
      console.log('country', country[0].long_name);
      //document.getElementsByName('country')[0].value = country[0].long_name;
      Address['country']=country[0].long_name;
      Address['countryCode']=country[0].short_name;
    }
    let state = placeArr.filter(el => el.types.includes('administrative_area_level_1'));
    if(state.length > 0){
      console.log('state', state.length);
      //document.getElementsByName('state')[0].value = state[0].long_name;
      Address['state']=state[0].long_name;
      Address['stateCode']=state[0].short_name;
    }
    let city = placeArr.filter(el => el.types.includes('locality'));
    if(city.length > 0){
      console.log(city.length, 'CITY')
      console.log('city', city[0].long_name);
      //document.getElementsByName('city')[0].value = city[0].long_name;
      Address['city']=city[0].long_name;
    }
    let postalCode = placeArr.filter(el => el.types.includes('postal_code'));
    if(postalCode.length > 0){
      console.log('postalCode', postalCode[0].long_name);
      //document.getElementsByName('zipcode')[0].value = postalCode[0].long_name;
      Address['zipcode']=postalCode[0].long_name;
    }
    let addressLine2 = '';
    placeArr.some(el => {
      if (el.types.includes('locality')) {
          //console.log('yes, I entered the if statement')
          return true;
      } else {
        if(el.long_name) {
         console.log(el.long_name);
         addressLine2 += el.long_name+ ', ';
         Address['addressLine2']=addressLine2;
        }
      }
    });
    console.log(addressLine2);
    //console.log(document.getElementsByName('addressLine1')[0].value);
    //console.log((document.getElementsByName('addressLine1')[0].value).split(',')[0]);
    //document.getElementsByName('addressLine1')[0].value = (document.getElementsByName('addressLine1')[0].value).split(',')[0];
    //document.getElementsByName('addressLine2')[0].value = addressLine2;
  
    //Address['addressLine1']=(document.getElementsByName('addressUS')[0].value).split(',')[0];
    Address['placeId']=place.place_id;
    console.log(Address, 'adress', beneficialOwner);
    /* beneficialOwner[index]['boResidentialCountryJurisdiction'] = Address.country;
    beneficialOwner[index]['boResidentialCountryJurisdictionCode'] = Address.countryCode;
    beneficialOwner[index]['boResidentialAddress'] = Address.addressLine2;
    beneficialOwner[index]['boResidentialState'] = Address.state;
    beneficialOwner[index]['boResidentialStateCode'] = Address.stateCode;
    beneficialOwner[index]['boResidentialCity'] = Address.city;
    beneficialOwner[index]['boResidentialZipcode'] = Address.zipcode;
    beneficialOwner[index]['boResidentialAddressPlaceId'] = Address.placeId; */
    
    //setAddress(Address);
    //console.log(address, 'PERMANENT_ADDRESS');

    //setBeneficialOwner(beneficialOwner);
    console.log(beneficialOwner);
    /* setBeneficialOwner((stateBeneficialOwner) => {
      console.log(stateBeneficialOwner);
      newData['boInformation']=stateBeneficialOwner;
      return stateBeneficialOwner;
    }); */

    setBeneficialOwner(el => [...el]);
    setBeneficialOwner((stateBeneficialOwner) => {
      console.log(stateBeneficialOwner);

      stateBeneficialOwner[index]['boResidentialCountryJurisdiction'] = Address.country;
      stateBeneficialOwner[index]['boResidentialCountryJurisdictionCode'] = Address.countryCode;
      stateBeneficialOwner[index]['boResidentialAddress'] = Address.addressLine2;
      stateBeneficialOwner[index]['boResidentialState'] = Address.state;
      stateBeneficialOwner[index]['boResidentialStateCode'] = Address.stateCode;
      stateBeneficialOwner[index]['boResidentialCity'] = Address.city;
      stateBeneficialOwner[index]['boResidentialZipcode'] = Address.zipcode;
      stateBeneficialOwner[index]['boResidentialAddressPlaceId'] = Address.placeId;

      console.log(stateBeneficialOwner);
      newData['boInformation']=stateBeneficialOwner;

      return stateBeneficialOwner;
    });

    setInputs(newData);
    console.log(newData);

    //console.log('aaa', inputs, 'qq', beneficialOwner);
  }

  return (
    <div>
      {/* <div className="d-flex gap-1 justify-content-between align-items-center">
        <h5 className="mb-0">Beneficial owner(s)</h5>
        <Button type="button" variant="text" onClick={handleAddBeneficialOwner}>+ <u>Add beneficial owner</u></Button>
      </div> */}
      <Accordion activeKey={activeKey}>
        {beneficialOwner.length > 0 && beneficialOwner.map((item, index) => {
          return(<>
            <Accordion.Item eventKey={`${index}`} id={"bo"+(index+1)} className="mb-3">
              <div className="d-flex gap-1">
                <Accordion.Header className="flex-grow-1" onClick={() => setActiveKey(activeKey === `${index}` ? null : `${index}`)}>Beneficial Owner #{index+1}</Accordion.Header>
                {beneficialOwner.length !== 1 && <Button type="button" variant="transparent" onClick={(e) => handleRemoveBeneficialOwner(e, index)}>&times;</Button>}
              </div>
              <Accordion.Body>
                <section>
                  <fieldset className={(item.isExemptEntity !== '') ? "d-none" : "d-block"}>
                    <div className="d-flex align-items-start gap-2 mt-3 mb-2">
                      <h5>Parent/Guardian information instead of minor child</h5>
                      <Button type="button" variant='transparent' className='info-help-btn border-0 p-0 ms-auto' onClick={() => handleShowInfoModal("Parent/Guardian information instead of minor child", "<div><p>If the beneficial owner for the reporting company is a minor child, you may check this box and complete Part III with information about a parent or legal guardian of the minor child.</p></div>")}>
                        <img src={require('./../assets/images/help-circle.png')} alt="Help Text" className="img-fluid" />
                      </Button>
                    </div>
                    <div className="mb-3">
                      <div><p>Check if the Beneficial Owner is a minor child and the parent/guardian information is provided instead</p></div>
                    </div>
                    <div className="mb-4">
                      <Form.Check type="checkbox" name="isParentGuardianInformation" value={"Parent/Guardian information instead of minor child" || ""} onChange={(e) => handleChangeBOInput(e, index, undefined)} id={"isParentGuardianInformation"+index} label={<span>Parent/Guardian information instead of minor child</span>} checked={item.isParentGuardianInformation === "Parent/Guardian information instead of minor child"} />
                    </div>
                  </fieldset>
                </section>

                <section>
                  <fieldset className={(item.isExemptEntity !== '') ? "d-none" : "d-block"}>
                    <div className="d-flex align-items-start gap-2 mt-3 mb-2">
                      <h5>Beneficial Owner FinCEN ID</h5>
                      <Button type="button" variant='transparent' className='info-help-btn border-0 p-0 ms-auto' onClick={() => handleShowInfoModal("Beneficial Owner FinCEN ID", "<div><p>Reporting companies may provide a FinCEN Identifier for a beneficial owner instead of rest of the information below.</p><p>Enter the FinCEN ID as a single text string. If a FinCEN ID for the beneficial owner is not provided, information about the beneficial owner must be provided in rest of the fields below.</p></div>")}>
                        <img src={require('./../assets/images/help-circle.png')} alt="Help Text" className="img-fluid" />
                      </Button>
                    </div>
                    <div className="mb-4">
                      <div className="d-flex align-items-start gap-2"><label className="form-label"><span>FinCEN ID</span> </label></div>
                      <Form.Control 
                        name="boFincenId" 
                        value={item.boFincenId || ""}
                        onChange={(e) => handleChangeBOInput(e, index, undefined)} 
                        onKeyDown={handleDelete} 
                        type="text" 
                        pattern="[0-9]{10}|[0-9]{12}"
                        title="Must be 10 or 12 numeric characters"
                      />
                    </div>
                  </fieldset>
                </section>
                <section>
                  <fieldset className={(item.boFincenId === null || item.boFincenId === '') ? "d-block" : "d-none"}>
                    <div className="d-flex align-items-start gap-2 mt-3 mb-2">
                      <h5>Exempt entity</h5>
                      <Button type="button" variant='transparent' className='info-help-btn border-0 p-0 ms-auto' onClick={() => handleShowInfoModal("Exempt entity", "<div><p>Check this box if the beneficial owner holds its ownership interest in the reporting company exclusively through one or more exempt entities, and the name of that exempt entity or entities are being reported in lieu of the beneficial owner's information. If checked, provide the legal name of the exempt entity in <b>Individual's last name or entity's legal</b> name field.</p></div>")}>
                        <img src={require('./../assets/images/help-circle.png')} alt="Help Text" className="img-fluid" />
                      </Button>
                    </div>
                    <div className="mb-4">
                      <Form.Check type="checkbox" name="isExemptEntity" value={"Exempt entity" || ""} onChange={(e) => handleChangeBOInput(e, index, undefined)} id={"isExemptEntity"+index} label={<span>Exempt entity</span>} checked={item.isExemptEntity === "Exempt entity"} />
                    </div>
                  </fieldset>
                </section>
                <section>
                  <fieldset className={(item.boFincenId !== '' && item.boFincenId.length !== 12) ? "d-none" : "d-block"} disabled={(item.boFincenId !== '' && item.boFincenId.length !== 12) ? true : false}>
                    <div className="d-flex align-items-start gap-2 mt-3 mb-2">
                      <div className={item.boFincenId?.length === 12 ? "d-none" : "d-block"}>
                        <h5>Legal name and date of birth</h5>
                        <Button type="button" variant='transparent' className='info-help-btn border-0 p-0 ms-auto' onClick={() => handleShowInfoModal("Legal name and date of birth", "<div><h5>Last Name (or legal entity name of Beneficial Owner)</h5><p>Enter the beneficial owner's legal last name or the exempt entity's legal name. An entity's legal name is the name on the articles of incorporation or other document that created or registered the entity. Do not abbreviate names unless an abbreviation is part of the legal name.</p><h5>First name</h5><p>Enter the beneficial owner's legal first name.</p><h5>Middle name</h5><p>Enter the beneficial owner's middle name if the beneficial owner's legal name includes a middle name. Leave this item blank if the beneficial owner does not have a middle name.</p><h5>Suffix</h5><p>Enter the beneficial owner's suffix such as JR, SR, III, etc., if the beneficial owner has a suffix to their legal name. Leave this item blank if the beneficial owner's legal name does not include a suffix.</p><h5>Date of birth</h5><p>Enter the beneficial owner's date of birth, using the format MM/DD/YYYY where MM = month, DD = day, and YYYY = year (e.g., 01/25/1970). The month, day, and year must be provided; no partial dates will be accepted.</p></div>")}>
                          <img src={require('./../assets/images/help-circle.png')} alt="Help Text" className="img-fluid" />
                        </Button>
                      </div>
                    </div>
                    <div className="mb-4">
                      <div className="d-flex align-items-start gap-2"><label className="form-label"><span>Last Name (or legal entity name of Beneficial Owner)</span> {(item.boFincenId === null || item.boFincenId === '' || item.boFincenId.length === 12) && <span className="txt-red">*</span>}</label></div>
                      <Form.Control 
                        name="boIndividualLastNameEntityLegalName" 
                        value={item.boIndividualLastNameEntityLegalName || ""}
                        onChange={(e) => handleChangeBOInput(e, index, undefined)} 
                        onKeyDown={handleDelete} 
                        type="text" 
                        required={item.boFincenId === null || item.boFincenId === "" || item.boFincenId.length === 12}
                        pattern="^[A-Za-z0-9 _.\-]+$" 
                        inputmode="text" 
                        title="Only letters, numbers, space, underscore, hyphen, and dot are allowed."
                      />
                    </div>
                    <fieldset className={(item.boFincenId !== '' || item.isExemptEntity !== '') ? "d-none" : "d-block"} disabled={(item.boFincenId !== '' || item.isExemptEntity !== '') ? true : false}>
                      <div className="mb-4">
                        <div className="d-flex align-items-start gap-2"><label className="form-label"><span>First name</span> {(item.boFincenId === null || item.boFincenId === '') && <span className="txt-red">*</span>}</label></div>
                        <Form.Control 
                          name="boFirstName" 
                          value={item.boFirstName || ""}
                          onChange={(e) => handleChangeBOInput(e, index, undefined)} 
                          onKeyDown={handleDelete} 
                          type="text" 
                          required={item.boFincenId === null || item.boFincenId === ''}
                        />
                      </div>
                      <div className="mb-4">
                        <div className="d-flex align-items-start gap-2"><label className="form-label"><span>Middle name</span> </label></div>
                        <Form.Control 
                          name="boMiddleName" 
                          value={item.boMiddleName || ""}
                          onChange={(e) => handleChangeBOInput(e, index, undefined)} 
                          onKeyDown={handleDelete} 
                          type="text" 
                        />
                      </div>
                      <div className="mb-4">
                        <div className="d-flex align-items-start gap-2"><label className="form-label"><span>Suffix</span> </label></div>
                        <Form.Control 
                          name="boSuffix" 
                          value={item.boSuffix || ""}
                          onChange={(e) => handleChangeBOInput(e, index, undefined)} 
                          onKeyDown={handleDelete} 
                          type="text" 
                        />
                      </div>
                      <div className="">
                        <div className="d-flex align-items-start gap-2"><label className="mr-3 form-label"><span>Date of birth</span> {(item.boFincenId === null || item.boFincenId === '') && <span className="txt-red">*</span>}</label></div>
                        <Form.Control 
                          name="boDateOfBirth" 
                          value={item.boDateOfBirth || ""}
                          onChange={(e) => handleChangeBOInput(e, index, undefined)} 
                          onKeyDown={handleDelete} 
                          type="date" 
                          required={item.boFincenId === null || item.boFincenId === ''} 
                          max={new Date().toISOString().split("T")[0]}
                        />
                      </div>
                    </fieldset>
                  </fieldset>
                </section>
                <section>
                  <fieldset className={(item.boFincenId !== '' || item.isExemptEntity !== '') ? "d-none" : "d-block"} disabled={(item.boFincenId !== '' || item.isExemptEntity !== '') ? true : false}>
                    <div className="d-flex align-items-start gap-2 mt-3 mb-2">
                      <h5>Residential address</h5>
                      <Button type="button" variant='transparent' className='info-help-btn border-0 p-0 ms-auto' onClick={() => handleShowInfoModal("Residential address", "<div><h5>Beneficial Owner address</h5><p>Enter the beneficial owner's residential street address information, including the city, country or jurisdiction, State, and ZIP code or foreign postal code. U.S. Territories are included in the drop-down menu for “Country/Jurisdiction.” The “State” field will be automatically populated when a U.S. Territory is selected in the “Country/ Jurisdiction” field. “State” field is required if the country selected in “Country/ Jurisdiction” field is the United States, Canada, or Mexico.</p></div>")}>
                        <img src={require('./../assets/images/help-circle.png')} alt="Help Text" className="img-fluid" />
                      </Button>
                    </div>
                    <div className="mb-4">
                      <div className="d-flex align-items-start gap-2"><label className="mr-3 form-label"><span>Country/Jurisdiction</span> {(item.boFincenId === null || item.boFincenId === '') && <span className="txt-red">*</span>}</label></div>
                      <Form.Control 
                        name="boResidentialCountryJurisdiction" 
                        value={item.boResidentialCountryJurisdiction || ""} 
                        onBlur={handleOptions} 
                        onChange={(e) => handleChangeBOInput(e, index, "country")} 
                        type="text" 
                        required={item.boFincenId === null || item.boFincenId === ''}
                        list={"boResidentialCountryJurisdiction"+(index+1)} 
                      />
                      <datalist id={"boResidentialCountryJurisdiction"+(index+1)}>
                        <option></option>
                        {countryLookup &&
                          countryLookup.map((item, index) => <option value={item.value} key={index} data-value={item.country_code}>{item.value}</option>)
                        }
                      </datalist>
                    </div>
                    <div className="mb-4">
                      <div className="d-flex align-items-start gap-2"><label className="form-label"><span>Address (number, street, and apt. or suite no.)</span> {(item.boFincenId === null || item.boFincenId === '') && <span className="txt-red">*</span>}</label></div>
                      {/* <Form.Control 
                        name="boResidentialAddress" 
                        value={item.boResidentialAddress || ""}
                        onChange={(e) => handleChangeBOInput(e, index, undefined)} 
                        onKeyDown={handleDelete} 
                        type="text" 
                        required={item.boFincenId === null || item.boFincenId === ''}
                      /> */}
                      <Autocomplete apiKey="AIzaSyBafPK5TmCxMIAXCroevkGly1srtn6WB8s"           
                        onPlaceSelected={(place) => {
                          handlePlaceSelectedBO(place, index);
                        }} 
                        options={{ types: ["geocode", "establishment"],}} 
                        name="boResidentialAddress" 
                        required={item.boFincenId === null || item.boFincenId === ''} 
                        className="form-control" 
                        placeholder="" 
                        value={item.boResidentialAddress || ""}
                        onChange={(e) => handleChangeBOInput(e, index, undefined)} 
                        onKeyDown={handleDelete} 
                      />
                    </div>
                    <div className="mb-4">
                      <div className="d-flex align-items-start gap-2"><label className="mr-3 form-label"><span>State</span> {(item.boFincenId === null || item.boFincenId === '') && <span className="txt-red">*</span>}</label></div>
                      <Form.Control 
                        name="boResidentialState" 
                        value={item.boResidentialState || ""} 
                        onBlur={handleOptions} 
                        onChange={(e) => handleChangeBOInput(e, index, "state")} 
                        type="text" 
                        required={item.boFincenId === null || item.boFincenId === ''}
                        readOnly={item.boResidentialCountryJurisdiction === "American Samoa" || item.boResidentialCountryJurisdiction === "Guam" || item.boResidentialCountryJurisdiction === "Marshall Islands" || item.boResidentialCountryJurisdiction === "Micronesia" || item.boResidentialCountryJurisdiction === "Northern Mariana Islands" || item.boResidentialCountryJurisdiction === "Palau" || item.boResidentialCountryJurisdiction === "Puerto Rico" || item.boResidentialCountryJurisdiction === "Virgin Islands (US)"}
                        list={"boResidentialState"+(index+1)} 
                      />
                      <datalist id={"boResidentialState"+(index+1)}>
                        <option></option>
                        {stateLookup &&
                          stateLookup.map((item, index) => <option value={item.value} key={index} data-value={item.state_code}>{item.value}</option>)
                        }
                      </datalist>
                    </div>
                    <div className="mb-4">
                      <div className="d-flex align-items-start gap-2"><label className="mr-3 form-label"><span>City</span> {(item.boFincenId === null || item.boFincenId === '') && <span className="txt-red">*</span>}</label></div>
                      <Form.Control 
                        name="boResidentialCity" 
                        value={item.boResidentialCity || ""} 
                        onChange={(e) => handleChangeBOInput(e, index, undefined)} 
                        type="text" 
                        required={item.boFincenId === null || item.boFincenId === ''}
                        list={"boResidentialCity"+(index+1)} 
                      />
                      <datalist id={"boResidentialCity"+(index+1)}>
                        <option></option>
                        {cityLookup &&
                          cityLookup.map((item, index) => <option value={item.value} key={index}>{item.value}</option>)
                        }
                      </datalist>
                    </div>
                    <div className="mb-4">
                      <div className="d-flex align-items-start gap-2"><label className="form-label"><span>ZIP/Foreign postal code</span> {(item.boFincenId === null || item.boFincenId === '') && <span className="txt-red">*</span>}</label></div>
                      <Form.Control 
                        name="boResidentialZipcode" 
                        value={item.boResidentialZipcode || ""}
                        onChange={(e) => handleChangeBOInput(e, index, undefined)} 
                        onKeyDown={handleDelete} 
                        type="text" 
                        required={item.boFincenId === null || item.boFincenId === ''}
                        pattern="[A-Z0-9]{5}"
                        title="Must equal 5 characters"
                      />
                    </div>
                    <div className="mb-4">
                      <div className="d-flex align-items-start gap-2"><label className="form-label"><span>Social security number (or similar number if non-U.S. person)</span> {(item.boResidentialCountryJurisdiction !== "United States") && <span className="txt-red">*</span>}</label></div>
                      <Form.Control 
                        name="boSSN" 
                        value={item.boSSN || ""}
                        onChange={(e) => handleChangeBOInput(e, index, undefined)} 
                        onKeyDown={handleDelete} 
                        type="number" 
                        min="0" 
                        required={item.boResidentialCountryJurisdiction !== "United States"}
                      />
                    </div>
                  </fieldset>
                </section>
                <section>
                  <fieldset className={(item.boFincenId !== '' || item.isExemptEntity !== '') ? "d-none" : "d-block"} disabled={(item.boFincenId !== '' || item.isExemptEntity !== '') ? true : false}>
                    <div className="d-flex align-items-start gap-2 mt-3 mb-2">
                      <h5>Form of identification and issuing jurisdiction</h5>
                      <Button type="button" variant='transparent' className='info-help-btn border-0 p-0 ms-auto' onClick={() => handleShowInfoModal("Form of identification and issuing jurisdiction", "<div><h5>Identifying document type</h5><p>Select the beneficial owner's non-expired identifying document type from the list of acceptable identification documents: a State-issued driver's license, a State/local/Tribe-issued identification document issued for the purpose of identifying the individual, a U.S. passport, or, only if the beneficial owner does not have one of these three types of identifying documents, a foreign passport.</p><h5>Identifying document number</h5><p>Enter the identifying document number from the beneficial owner's identifying document.</p><h5>Identifying document issuing jurisdiction</h5><p>Select country/jurisdiction that issued the beneficial owner's identifying document.</p><p>If a U.S. Territory issued the identifying document, select the applicable U.S. Territory (the same U.S. Territory will then be automatically populated in “State” field as a result).</p><p>Enter State issuing the identifying document when country/jurisdiction is United States, if applicable.</p><p>If a local or Tribal government issued the identifying document, select “United States” and then select the applicable local or Tribal description.</p><p>If the name of the relevant local or Tribal jurisdiction is not included in the drop-down menu, select “Other” and enter the name of the local or Tribal jurisdiction in the next field.</p></div>")}>
                        <img src={require('./../assets/images/help-circle.png')} alt="Help Text" className="img-fluid" />
                      </Button>
                    </div>
                    <div className="mb-4">
                      <div className="d-flex align-items-start gap-2"><label className="mr-3 form-label"><span>Identifying document type</span> {(item.boFincenId === null || item.boFincenId === '') && <span className="txt-red">*</span>}</label></div>
                      <Form.Select 
                        name="boDocumentIdType" 
                        value={item.boDocumentIdType || ""}
                        onChange={(e) => handleChangeBOInput(e, index, undefined)} 
                        required={item.boFincenId === null || item.boFincenId === ''} 
                      >
                        <option value="">Select</option>
                        <option value="State issued driver's license">State issued driver's license</option>
                        <option value="State/local/tribe-issued ID">State/local/tribe-issued ID</option>
                        <option value="U.S. passport">U.S. passport</option>
                        <option value="Foreign passport">Foreign passport</option>
                      </Form.Select>
                    </div>
                    <div className="mb-4">
                      <div className="d-flex align-items-start gap-2"><label className="form-label"><span>Identifying document number</span> {(item.boFincenId === null || item.boFincenId === '') && <span className="txt-red">*</span>}</label></div>
                      <Form.Control 
                        name="boDocumentIdNumber" 
                        value={item.boDocumentIdNumber || ""}
                        onChange={(e) => handleChangeBOInput(e, index, undefined)} 
                        onKeyDown={handleDelete} 
                        type="text" 
                        required={item.boFincenId === null || item.boFincenId === ''}
                      />
                    </div>
                    <div className="mb-4">
                      <div className="d-flex align-items-start gap-2"><label className="form-label"><span>Identifying document issuing jurisdiction</span> </label></div>
                    </div>
                    <div className="mb-4">
                      <div className="d-flex align-items-start gap-2"><label className="mr-3 form-label"><span>a. Country/Jurisdiction</span> {(item.boFincenId === null || item.boFincenId === '') && <span className="txt-red">*</span>}</label></div>
                      <Form.Control 
                        name="boDocumentIdIssuingCountryJurisdiction" 
                        value={item.boDocumentIdIssuingCountryJurisdiction || ""} 
                        onBlur={handleOptions} 
                        onChange={(e) => handleChangeBOInput(e, index, "country")} 
                        type="text" 
                        required={item.boFincenId === null || item.boFincenId === ''}
                        readOnly={item.boDocumentIdType === "U.S. passport"}
                        list={"boDocumentIdIssuingCountryJurisdiction"+(index+1)} 
                      />
                      <datalist id={"boDocumentIdIssuingCountryJurisdiction"+(index+1)}>
                        <option></option>
                        {countryLookup &&
                          countryLookup.map((item, index) => <option value={item.value} key={index} data-value={item.country_code}>{item.value}</option>)
                        }
                      </datalist>
                    </div>
                    <div className="mb-4">
                      <div className="d-flex align-items-start gap-2">
                        <label className="mr-3 form-label">
                          <span>b. State</span> 
                          {
                          ((item.boDocumentIdType === "State issued driver's license")
                          ||
                          (item.boDocumentIdType === "State/local/tribe-issued ID" && (item.boDocumentIdIssuingLocalTribal === null || item.boDocumentIdIssuingLocalTribal === ''))) && 
                            <span className="txt-red">*</span>
                          }
                          </label>
                      </div>
                      <Form.Control 
                        name="boDocumentIdIssuingState" 
                        value={item.boDocumentIdIssuingState || ""} 
                        onBlur={handleOptions} 
                        onChange={(e) => handleChangeBOInput(e, index, "state")} 
                        type="text" 
                        required={
                          (item.boDocumentIdType === "State issued driver's license")
                          ||
                          (item.boDocumentIdType === "State/local/tribe-issued ID" && (item.boDocumentIdIssuingLocalTribal === null || item.boDocumentIdIssuingLocalTribal === ''))
                        }
                        readOnly={item.boDocumentIdIssuingCountryJurisdiction === "American Samoa" || item.boDocumentIdIssuingCountryJurisdiction === "Guam" || item.boDocumentIdIssuingCountryJurisdiction === "Marshall Islands" || item.boDocumentIdIssuingCountryJurisdiction === "Micronesia" || item.boDocumentIdIssuingCountryJurisdiction === "Northern Mariana Islands" || item.boDocumentIdIssuingCountryJurisdiction === "Palau" || item.boDocumentIdIssuingCountryJurisdiction === "Puerto Rico" || item.boDocumentIdIssuingCountryJurisdiction === "Virgin Islands (US)"}

                        disabled={item.boDocumentIdType === "U.S. passport" || item.boDocumentIdType === "Foreign passport" || (item.boDocumentIdType === "State/local/tribe-issued ID" && item.boDocumentIdIssuingLocalTribal !== '')}
                        list={"boDocumentIdIssuingState"+(index+1)} 
                      />
                      <datalist id={"boDocumentIdIssuingState"+(index+1)}>
                        <option></option>
                        {stateLookup &&
                          stateLookup.map((item, index) => <option value={item.value} key={index} data-value={item.state_code}>{item.value}</option>)
                        }
                      </datalist>
                    </div>
                    <div className="mb-4">
                      <div className="d-flex align-items-start gap-2">
                        <label className="mr-3 form-label">
                          <span>c. Local/Tribal</span> 
                          {(item.boDocumentIdType === "State/local/tribe-issued ID" && (item.boDocumentIdIssuingState === null || item.boDocumentIdIssuingState === '')) && 
                            <span className="txt-red">*</span>
                          }
                        </label>
                      </div>
                      <Form.Control 
                        name="boDocumentIdIssuingLocalTribal" 
                        value={item.boDocumentIdIssuingLocalTribal || ""} 
                        onBlur={handleOptions} 
                        onChange={(e) => handleChangeBOInput(e, index, "local_tribal")} 
                        type="text" 
                        required={item.boDocumentIdType === "State/local/tribe-issued ID" && (item.boDocumentIdIssuingState === null || item.boDocumentIdIssuingState === '')}
                        disabled={item.boDocumentIdType === "" || item.boDocumentIdType !== "State/local/tribe-issued ID" || (item.boDocumentIdType === "State/local/tribe-issued ID" && item.boDocumentIdIssuingState !== '')}
                        list={"boDocumentIdIssuingLocalTribal"+(index+1)} 
                      />
                      <datalist id={"boDocumentIdIssuingLocalTribal"+(index+1)}>
                        <option></option>
                        {localTribalLookup &&
                          localTribalLookup.map((item, index) => <option value={item.value} key={index} data-value={item.local_tribal_code}>{item.value}</option>)
                        }
                      </datalist>
                    </div>
                    <div className="mb-4">
                      <div className="d-flex align-items-start gap-2">
                        <label className="form-label">
                          <span>d. Other local/Tribal description</span> 
                          {item.boDocumentIdIssuingLocalTribal === "Other" && <span className="txt-red">*</span>}
                        </label>
                      </div>
                      <Form.Control 
                        name="boDocumentIdIssuingLocalOtherTribe" 
                        value={item.boDocumentIdIssuingLocalOtherTribe || ""}
                        onChange={(e) => handleChangeBOInput(e, index, undefined)} 
                        onKeyDown={handleDelete} 
                        type="text" 
                        required={item.boDocumentIdIssuingLocalTribal === "Other"}
                        disabled={item.boDocumentIdIssuingLocalTribal === "" || item.boDocumentIdIssuingLocalTribal !== "Other"}
                      />
                    </div>
                  </fieldset>
                </section>
                <section>
                  <fieldset className={(item.boFincenId !== '' || item.isExemptEntity !== '') ? "d-none" : "d-block"}>
                    <div className="d-flex align-items-start gap-2 mt-3 mb-2">
                      <h5>Identifying document</h5>
                      <Button type="button" variant='transparent' className='info-help-btn border-0 p-0 ms-auto' onClick={() => handleShowInfoModal("Identifying document", "<div><p>Drag and drop a file or click \"Browse\" to attach a clear, readable image of the page or side of the identifying document referenced in Identifying document number field containing the unique identifying number and other identifying data. </p><p>Drag and drop another file or click to upload another file. An attachment to a BOIR cannot be larger than four (4) megabytes of data and must be in one of the following formats: JPG/JPEG, PNG, or PDF. Only one (1) attachment file may be added per beneficial owner. Attachment file names should not contain spaces, and can only contain letters, numbers, or the following characters !@#$%()_-.=+[]|;~</p></div>")}>
                        <img src={require('./../assets/images/help-circle.png')} alt="Help Text" className="img-fluid" />
                      </Button>
                    </div>
                    <div className="mb-4">
                      <div className="d-flex align-items-start gap-2"><label className="form-label"><span>Identifying document image</span> {(item.boFincenId === '' && item.isExemptEntity === '') && <span className="txt-red">*</span>}</label></div>
                      <div className="position-relative">
                        <Form.Label htmlFor={"boIdentifyingDocumentImage"+(index+1)} className='upload'>{item.boIdentifyingDocumentImage || document.getElementById("boIdentifyingDocumentImage"+(index+1))?.files[0]?.name ? <div className="d-flex gap-1 justify-content-between align-items-center"><b>{document.getElementById("boIdentifyingDocumentImage"+(index+1))?.files[0]?.name || item.boIdentifyingDocumentImage}</b>Tap to upload again</div> : <>Drag and Drop the file here or <u>Browse</u>. <br/>[Max 4MB]</>}</Form.Label>
                        <Form.Control id={"boIdentifyingDocumentImage"+(index+1)} name="boIdentifyingDocumentImage" type="file" accept=".pdf, .jpeg, .jpg, .png" onChange={(e) => handleChangeBOInput(e, index, undefined)} required={(item.boFincenId === '' && item.isExemptEntity === '' && item.boIdentifyingDocumentImage === '') ? true : false} />
                      </div>
                    </div>
                  </fieldset>
                </section>
              </Accordion.Body>
            </Accordion.Item>
          </>);
        })}
      </Accordion>
    </div>
  );
});

export default BeneficialOwner;