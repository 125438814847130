import React, { useState } from 'react';
import { useStripe, useElements, CardElement} from '@stripe/react-stripe-js';
import { Button, Form, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import axios from "axios";

const serverConfig = require("./../../config/server.js");

export default function CreditCard({handlePaymentSet, amount, isAuthorizedSigner, responseMessage}) {

  const stripe = useStripe();
  const elements = useElements();

  const [inputs, setInputs] = useState({});
  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [couponCodeValid, setCouponCodeValid] = useState(false);
  const [amountAfterDiscount, setAmountAfterDiscount] = useState(null);

  const checkCouponCode = () => {
    console.log(couponCode);
    axios.post(serverConfig.api_base_url + "validate_coupon",{coupon_code:couponCode})
      .then(response=>{
        console.log('validate_coupon', response);
        if(response.data.status === 200) {
          if(response.data.valid === 1){
            setCouponCodeValid(true);
          } else if(response.data.valid === 0){
            setCouponCodeValid(false);
          }
          setAmountAfterDiscount(response.data.amountAfterDiscount);
        } else if(response.data.status === 301){
          //handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsProcessing(true);

    const billing_details = {
      name: inputs.name,
      email: inputs.email,
    };

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
      billing_details,
    });
    if (error) {
      console.error('Error creating payment method:', error);
      setMessage(error.message);
      setIsProcessing(false);
      return;
    }
    try {
      const response = await fetch(serverConfig.fapi_base_url + 'submit_payment', {
        method: 'POST',
        //headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          name: inputs.name,
          email: inputs.email,
          address: {line1: "house no. 1", city:"Bangalore", state: "Karnataka", postal_code: "560078", country: "IN"},//comment this for US stripe account setup
          payment_method: paymentMethod.id,
          promotion_code: couponCode || undefined, // Optional promotion code
        }),
      });
      const data = await response.json();
      if (data.error) {
        console.error('Error creating subscription:', data.error);
        setMessage(data.error);
      } else if (data.clientSecret) {
        const result = await stripe.confirmCardPayment(data.clientSecret);
        if (result.error) {
          console.error('Error confirming card payment:', result.error);
          setMessage(result.error.message);
        } else {
          console.log('Payment successful:', result.paymentIntent);
          setMessage('Payment successful');
          handlePaymentSet(data.subscription_ID, couponCodeValid ? 1 : 0, amountAfterDiscount, couponCode, inputs.name, inputs.email); // Callback to handle post-payment actions
        }
      } else if (data.message) {
        console.log(data.message);
        if(data.message === "Invoice already paid"){
          console.log('Payment successful:', "Invoice already paid");
          setMessage('Payment successful');
          handlePaymentSet(data.subscriptionID, 1, amountAfterDiscount, couponCode, inputs.name, inputs.email);
        } else {
          setMessage(data.message);
        }
      }
    } catch (error) {
      console.error('Error handling request:', error);
      setMessage('An unexpected error occurred.');
    }

    setIsProcessing(false);
  };
  
  return (<Form onSubmit={handleSubmit}>
    <fieldset id="payment-form" disabled={!isAuthorizedSigner}>
      <div className='mb-2'>
        <Form.Group className="mb-2">
          <Form.Label className='mb-0'>
            Name of Authorized Signer <span className='txt-red'>*</span>
            <OverlayTrigger placement="top" overlay={<Tooltip>This is the person who is Authorized Signer of the company</Tooltip>}>
              <span className="info"><i>i</i></span>
            </OverlayTrigger>
          </Form.Label>
          <Form.Control type="text" value={inputs.name} name="name" onChange={(e) => setInputs({...inputs, name: e.target.value})} required />
        </Form.Group>
        <Form.Group className="mb-2">
          <Form.Label className='mb-0'>Email of Authorized Signer <span className='txt-red'>*</span></Form.Label>
          <Form.Control type="email" value={inputs.email} name="email" onChange={(e) => setInputs({...inputs, email: e.target.value})} required />
        </Form.Group>
        
        <Form.Group className="mb-2">
          <Form.Label className='mb-0'>Coupon code</Form.Label>
          <Row className='align-items-center'>
            <Col>
              <Form.Control
                type="text"
                name="coupon_code"
                className='custom-input'
                value={couponCode}
                onChange={(e) => {setCouponCodeValid(false); setAmountAfterDiscount(null); setCouponCode(e.target.value.toUpperCase());}}
              />
            </Col>
            <Col xs="auto"><Button type="button" variant="transparent" className='p-2' onClick={checkCouponCode} disabled={couponCode === ""}>Apply</Button></Col>
          </Row>
        </Form.Group>
        <Form.Group>
          <CardElement id="payment-element" />
        </Form.Group>
      </div>

      {/* Show any error or success messages */}
      {message && <div id="payment-message" className={message === "Payment successful" ? "mt-2 txt-green" : "mt-2 txt-red"}>{message}</div>}
      {responseMessage && <div className="mt-2 txt-green">{responseMessage}</div>}

      <div className="subscription-box p-4 mt-2">
        <h5>Annual Subscription</h5>
        <p>{couponCodeValid ? <><s>$ {amount}{/*  / Per year */}</s> $ {amountAfterDiscount}{/*  / Per year */}</> : <>$ {amount}{/*  / Per year */}</>}</p>
        {/* <div className="info-box">
          <span className="info-icon"></span>
          <span>Anything can be updated within one year.</span>
        </div> */}
        <div className="payment-btn-container mt-2"><Button type="submit" variant="primary" className="w-100" disabled={isProcessing || !stripe || !elements} id="submit">{isProcessing ? "Processing ... " : "Submit Payment"}</Button></div>
      </div>
    </fieldset>
  </Form>);
}