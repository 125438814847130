import React, { useState } from "react";
import { Container, Row, Col, Button, Modal, Form } from "react-bootstrap";
import Header from "../../components/Header";

const InviteUser = () => {
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});

  const handleShow = () => setShowModal(true);

  const handleClose = () => {
    setShowModal(false);
    setFormData({});
    setErrors({});
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = () => {
    let formErrors = {};
    if (!validateEmail(formData.email)) {
      formErrors.email = "Please enter a valid email address";
    }

    if (Object.keys(formErrors).length === 0) {
      // Handle invite logic here
      console.log(formData);
      handleClose();
    } else {
      setErrors(formErrors);
    }
  };

  return (
    <>
      <Header isLoggedIn={false} />
      <main className="main-wrap">
        <section className="welcome-text">
          <Container>
            <Row
              xs={1}
              md={2}
              className="justify-content-between align-items-center"
            >
              <Col>
                <div>
                  <img
                    src={require("../../assets/images/welcome-img-2.png")}
                    alt="Welcome"
                    className="img-fluid"
                  />
                </div>
              </Col>
              <Col>
                <div>
                  <h1>Welcome to BOIR</h1>
                  <Button onClick={handleShow} className="mt-2">
                    Invite User
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <Modal show={showModal} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Invite User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="formFullName">
                <Form.Label>Full Name</Form.Label>
                <Form.Control
                  type="text"
                  name="fullName"
                  value={formData.fullName || ""}
                  onChange={handleChange}
                  placeholder="Enter full name"
                />
              </Form.Group>
              <Form.Group controlId="formEmail" className="mt-3">
                <Form.Label>Email ID</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={formData.email || ""}
                  onChange={handleChange}
                  placeholder="Enter email"
                  isInvalid={!!errors.email}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formMobileNumber" className="mt-3">
                <Form.Label>Mobile Number</Form.Label>
                <Form.Control
                  type="text"
                  name="mobileNumber"
                  value={formData.mobileNumber || ""}
                  onChange={handleChange}
                  placeholder="Enter mobile number"
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="white" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleSubmit}>
              Send Invite
            </Button>
          </Modal.Footer>
        </Modal>
      </main>
    </>
  );
};

export default InviteUser;
