import React, { useState, useEffect } from 'react';
import { Container, Navbar, Nav, Button } from 'react-bootstrap';
import axios from "axios";
import UserService from '../services/UserService.js';

const serverConfig = require("../config/server.js");

const Header = (props) => {
  const isLoggedIn = props.isLoggedIn;

  const [logoBase64Text, setLogoBase64Text] = useState('');

  useEffect(() => {
    console.log(window.location.host);
    axios.post(serverConfig.api_base_url + "get_logo_image",{domainName:window.location.host})
    .then(response=>{
      console.log('get_logo_image response', response);
      if(response.data.status === 200) {
        setLogoBase64Text(response.data.logoBase64);
      } else if(response.data.status >= 400 && response.data.status <= 499){
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
	},[]);

  const handleLogout = () => {
    UserService.doLogout();
    sessionStorage.clear();
    localStorage.clear();
  }

  return (
    <header>
      <Navbar expand="md">
        <Container fluid className="justify-content-between">
          <Navbar.Brand href="/"><img src={logoBase64Text} alt="Logo" className="img-fluid" /></Navbar.Brand>
          {props.title && <div><b>{props.title}</b></div>}
          <div className='d-flex align-items-center gap-4 position-relative'>
            {/* <Button variant='transparent' className='border-0 p-0'>
              <img src={require('./../assets/images/help-circle.png')} alt="Ask Manatoko" className="img-fluid" />
              <span className='ask-text d-none d-sm-inline'>Ask Manatoko</span>
            </Button> */}
            <Navbar.Toggle aria-controls="basic-navbar-nav" className={isLoggedIn && 'logged-in'} />
            <Navbar.Collapse id="basic-navbar-nav">
              <ul className="navbar-nav">
                {isLoggedIn ? <li><Button variant="plain" onClick={handleLogout}>Logout</Button></li> : <li><Nav.Link href="/">Log In</Nav.Link></li>}
              </ul>
            </Navbar.Collapse>
          </div>
        </Container>
      </Navbar>
    </header>
  );
}

export default Header;