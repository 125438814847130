import React, { useState } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import Header from "../../components/Header";

const Signup = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [errorText, setErrorText] = useState("");
  const [submitted, setSubmitted] = useState(false); // State to track form submission

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate form fields
    if (!name.trim()) {
      setNameError("Name is required");
      return;
    }
    if (!email.trim()) {
      setEmailError("Email is required");
      return;
    }

    // Clear any previous errors
    setNameError("");
    setEmailError("");
    setErrorText("");

    // Simulate submission success
    setSubmitted(true);
  };

  return (
    <>
      <Header isLoggedIn={false} />
      <main className="main-wrap">
        <section className="signup-section">
          <Container className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
            <Row className="w-100">
              <Col className="d-flex flex-column align-items-center">
                {!submitted ? ( // Show form if not submitted
                  <>
                    <h1>Sign Up</h1>
                    <div className="d-flex flex-row mb-3 align-items-center">
                      <div className="font-semibold">Already Registered?</div>
                      <div
                        className="ms-2 fw-bold"
                        style={{ cursor: 'pointer', textDecoration: 'none' }}
                        onMouseOver={(e) => e.target.style.textDecoration = 'underline'}
                        onMouseOut={(e) => e.target.style.textDecoration = 'none'}
                      >
                        Log In
                      </div>
                    </div>
                    {errorText !== "" && (
                      <p className="error-display">{errorText}</p>
                    )}
                    <Form className="w-100" style={{ maxWidth: '400px' }} onSubmit={handleSubmit}>
                      <Form.Group controlId="formName">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter your Name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                        {nameError && <p className="error-text">{nameError}</p>}
                      </Form.Group>
                      <Form.Group controlId="formEmail" className="mt-3">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Enter your Email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        {emailError && <p className="error-text">{emailError}</p>}
                      </Form.Group>
                      <Button variant="primary" className="mt-4 mb-4 w-100" type="submit">
                        Sign Up
                      </Button>
                    </Form>
                  </>
                ) : (
                  // Show success message and image after submission
                  <div className="d-flex flex-column align-items-center">
                    <h1 className="mt-3">Check your email</h1>
                    <img src={require("../../assets/images/welcome.png")} alt="Check your email" />
                  </div>
                )}
              </Col>
            </Row>
          </Container>
        </section>
      </main>
    </>
  );
};

export default Signup;
