import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";

const ContactUs = () => {

  const[showContact, setShowContact] = useState(false);

  return (<div id="contact-us">
    <Button type="button" onClick={() => setShowContact(!showContact)}>Contact Us</Button>
    <div className={showContact ? "contact-us-container text-center show" : "contact-us-container text-center"}>
      <div className="position-relative">
        Contact us at <br/><a href="mailto:support@amberoon.com">support@amberoon.com</a>
        <Button type="button" variant="white" className="close-btn" onClick={() => setShowContact(false)}>x</Button>
      </div>
    </div>
  </div>);
}

export default ContactUs;