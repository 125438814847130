import React, { useEffect, useState } from 'react';
import { Container, Form, Button, Modal, Accordion } from 'react-bootstrap';
import axios from "axios";

const serverConfig = require("../config/server.js");

const ReviewEdit = ({ token, handleLogout, applicationId, setCurPageName, setstep, setIsLoading }) => {

  const [appData, setAppData] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    axios.post(serverConfig.api_base_url + "get_app_data", { token: token, application_id: applicationId })
      .then(response => {
        console.log(token);
        console.log("get_app_data response:", response);
        console.log(response.data.status);
        if (response.data.status === 200) {
          setAppData(response.data.items);
          setIsLoading(false);
        } else if (response.data.status === 407) {
          handleLogout();
        } else {
          alert(response.data.message);
          setIsLoading(false);
        }
      })
      .catch(err => console.log("error is", err));
  }, []);

  return (<>
    {appData.length > 0 && appData.map((item, index) => {
      return (item.group_disabled === 0 && item.group_name !== "agreements_disclosures" && item.group_name !== "review_and_edit" && <section key={index}>
        <h5 className='p-2 d-flex justify-content-between align-items-center' style={{ backgroundColor: "#cccccc" }}>
          {item.group_label}
          {item.group_name !== "documentation" && <Button
            variant="white"
            type="button"
            className='py-1 px-2'
            onClick={() => {
              setCurPageName(item.group_name);
              setstep(appData.findIndex((el) => el.group_name === item.group_name) + 1);
            }}>
            Edit
          </Button>}
        </h5>
        {item.group_sections.length > 0 && item.group_sections.sort((a, b) => a.section_seq_no - b.section_seq_no).map((sectionItem, sectionIndex) => {
          return (sectionItem.section_visible === 1 && <div key={sectionIndex}>
            {(sectionItem.section_title && item.group_name !== "agreements_disclosures") && <h6 style={{ color: "#aaaaaa", fontWeight: "400" }}>{sectionItem.section_title}</h6>}
            {sectionItem.section_entities.length > 0 && <div>
              {sectionItem.section_entities.sort((a, b) => a.entity_seq_no - b.entity_seq_no).map((entityItem, entityIndex) => {
                return (<>
                  {entityItem.entity_visible === 1 && entityItem.entity_name !== "captureSelfiePassport" && entityItem.entity_name !== "captureSelfiePassportChecks" && <div key={entityIndex} className='mt-2 mb-2'>

                    {item.group_name !== "payment" && item.group_name !== "agreements_disclosures" && <>
                      <Form.Label><span className='label' dangerouslySetInnerHTML={{ __html: entityItem.entity_label || entityItem.entity_name }} /></Form.Label> :
                      <div className='d-inline-block ms-2 text-primary' style={{ maxHeight: entityItem.entity_data_type.verify_id === 1 ? "100px" : "none" }}>
                        {
                          entityItem.entity_data_type.type === "image" ? entityItem.entity_data && <img src={'data:image/jpeg;base64,' + entityItem.entity_data} height="80" alt="Image" />
                            : <>
                              {entityItem.entity_data_type.boInfo === 1 ? entityItem.entity_data && <Accordion>
                                {JSON.parse(entityItem.entity_data).map((boItem, boIndex) => {
                                  console.log(boItem);
                                  return (<Accordion.Item eventKey={`${boIndex}`} className="mb-3" key={`bo-${boIndex}`}>
                                    <Accordion.Header>Beneficial Owner #{boIndex + 1}</Accordion.Header>
                                    <Accordion.Body>
                                    <Accordion.Body>
                                    <div className='text-primary'>
                                      <div><span className='label'>Parent/Guardian information instead of minor child</span> : {boItem.isExemptEntity !== '' ? "" : boItem.isParentGuardianInformation}</div>
                                      <div><span className='label'>FinCEN ID</span> : {boItem.isExemptEntity !== '' ? "" : boItem.boFincenId}</div>
                                      <div><span className='label'>Exempt entity</span> : {(boItem.boFincenId === null || boItem.boFincenId === '') ? boItem.isExemptEntity : ""}</div>
                                      {/* <div><span className='label'>Last Name (or legal entity name of Beneficial Owner)</span> : {boItem.boFincenId !== '' ? "" : boItem.boIndividualLastNameEntityLegalName}</div> */}
                                      <div><span className='label'>Last Name (or legal entity name of Beneficial Owner)</span> : {boItem?.boIndividualLastNameEntityLegalName}</div>
                                      <div><span className='label'>First name</span> : {(boItem.boFincenId !== '' || boItem.isExemptEntity !== '') ? "" : boItem.boFirstName}</div>
                                      <div><span className='label'>Middle name</span> : {(boItem.boFincenId !== '' || boItem.isExemptEntity !== '') ? "" : boItem.boMiddleName}</div>
                                      <div><span className='label'>Suffix</span> : {(boItem.boFincenId !== '' || boItem.isExemptEntity !== '') ? "" : boItem.boSuffix}</div>
                                      <div><span className='label'>Date of birth</span> : {(boItem.boFincenId !== '' || boItem.isExemptEntity !== '') ? "" : boItem.boDateOfBirth}</div>
                                    </div>
                                    <hr />
                                    <h6>Residential address</h6>
                                    <div className='text-primary'>
                                      <div><span className='label'>Country/Jurisdiction</span> : {(boItem.boFincenId !== '' || boItem.isExemptEntity !== '') ? "" : boItem.boResidentialCountryJurisdiction}</div>
                                      <div><span className='label'>Address (number, street, and apt. or suite no.)</span> : {(boItem.boFincenId !== '' || boItem.isExemptEntity !== '') ? "" : boItem.boResidentialAddress}</div>
                                      <div><span className='label'>State</span> : {(boItem.boFincenId !== '' || boItem.isExemptEntity !== '') ? "" : boItem.boResidentialState}</div>
                                      <div><span className='label'>City</span> : {(boItem.boFincenId !== '' || boItem.isExemptEntity !== '') ? "" : boItem.boResidentialCity}</div>
                                      <div><span className='label'>ZIP/Foreign postal code</span> : {(boItem.boFincenId !== '' || boItem.isExemptEntity !== '') ? "" : boItem.boResidentialZipcode}</div>
                                      <div><span className='label'>Social security number (or similar number if non-U.S. person)</span> : {(boItem.boFincenId !== '' || boItem.isExemptEntity !== '') ? "" : boItem.boSSN}</div>
                                    </div>
                                    <hr />
                                    <h6>Form of identification and issuing jurisdiction</h6>
                                    <div className='text-primary'>
                                      <div><span className='label'>Identifying document type</span> : {(boItem.boFincenId !== '' || boItem.isExemptEntity !== '') ? "" : boItem.boDocumentIdType}</div>
                                      <div><span className='label'>Identifying document number</span> : {(boItem.boFincenId !== '' || boItem.isExemptEntity !== '') ? "" : boItem.boDocumentIdNumber}</div>
                                    </div>
                                    <hr />
                                    <h6>Identifying document issuing jurisdiction</h6>
                                    <div className='text-primary'>
                                      <div><span className='label'>a. Country/Jurisdiction</span> : {(boItem.boFincenId !== '' || boItem.isExemptEntity !== '') ? "" : boItem.boDocumentIdIssuingCountryJurisdiction}</div>
                                      <div><span className='label'>b. State</span> : {(boItem.boFincenId !== '' || boItem.isExemptEntity !== '') ? "" : boItem.boDocumentIdIssuingState}</div>
                                      <div><span className='label'>c. Local/Tribal</span> : {(boItem.boFincenId !== '' || boItem.isExemptEntity !== '') ? "" : boItem.boDocumentIdIssuingLocalTribal}</div>
                                      <div><span className='label'>d. Other local/Tribal description</span> : {(boItem.boFincenId !== '' || boItem.isExemptEntity !== '') ? "" : boItem.boDocumentIdIssuingLocalOtherTribe}</div>
                                    </div>
                                    <div className='mt-2'>
                                      <p className='mb-0'><b>Identifying document</b></p>
                                      <span className='label'>Identifying document image</span> : {(boItem.boFincenId !== '' || boItem.isExemptEntity !== '') ? "" : boItem.boIdentifyingDocumentImageBase64 && <img src={boItem.boIdentifyingDocumentImageBase64.startsWith('data:image') ? boItem.boIdentifyingDocumentImageBase64 : 'data:image/jpeg;base64,'+boItem.boIdentifyingDocumentImageBase64} height="80" alt="Image" />}
                                    </div>
                                  </Accordion.Body>
                                    </Accordion.Body>
                                  </Accordion.Item>)
                                })}
                              </Accordion>
                                :
                                entityItem.entity_data_type.companyApplicantInformation === 1 ? entityItem.entity_data && <Accordion>
                                  {JSON.parse(entityItem.entity_data).map((companyItem, companyIndex) => (
                                    <Accordion.Item
                                      eventKey={`companyApplicant-${companyIndex}`}
                                      className="mb-3"
                                      key={`companyApplicant-${companyIndex}`}
                                    >
                                      <Accordion.Header>Company Applicant #{companyIndex + 1}</Accordion.Header>
                                      <Accordion.Body>
                                        {companyItem.applicantIdentifyingDocumentImageBase64 && (
                                          <div className="mt-2">
                                            <p className="mb-0">
                                              <b>Identifying Document</b>
                                            </p>
                                              <span className='label'>Identifying document image :</span>
                                            <img
                                              src={
                                                companyItem.applicantIdentifyingDocumentImageBase64.startsWith("data:image")
                                                  ? companyItem.applicantIdentifyingDocumentImageBase64
                                                  : "data:image/jpeg;base64," + companyItem.applicantIdentifyingDocumentImageBase64
                                              }
                                              height="80"
                                              alt="Identifying Document"
                                            />
                                          </div>
                                          )}
                                          <div className="text-primary">
                                          <div>
                                               <span className="label">FinCEN ID</span> : {companyItem.applicantFincenId || ""}
                                         </div>
                                          <div>
                                            <span className="label">Individual's last name</span> : {companyItem.applicantIndividualLastName || ""}
                                          </div>
                                          <div>
                                            <span className="label">First name</span> : {companyItem.applicantFirstName || ""}
                                          </div>
                                          <div>
                                            <span className="label">Middle name</span> : {companyItem.applicantMiddleName || ""}
                                          </div>
                                          <div>
                                            <span className="label">Suffix</span> : {companyItem.applicantSuffix || ""}
                                          </div>
                                          <div>
                                            <span className="label">Date of birth</span> : {companyItem.applicantDateOfBirth || ""}
                                          </div>
                                          <div>
                                            <span className="label">Job Title</span> : {companyItem.applicantTitle || ""}
                                          </div>
                                        </div>
                                        <hr />
                                        <h6>Current Address</h6>
                                        <div className="text-primary">
                                          <div>
                                            <span className="label">Address Type</span> : {companyItem.applicantAddressType || ""}
                                          </div>
                                          <div>
                                            <span className="label">Country/Jurisdiction</span> : {companyItem.applicantCurrentCountryJurisdiction || ""}
                                          </div>
                                          <div>
                                            <span className="label">Address (number, street, and apt. or suite no.)</span> : {companyItem.applicantCurrentAddress || ""}
                                          </div>
                                          <div>
                                            <span className="label">State</span> : {companyItem.applicantCurrentState || ""}
                                          </div>
                                          <div>
                                            <span className="label">City</span> : {companyItem.applicantCurrentCity || ""}
                                          </div>
                                          <div>
                                            <span className="label">ZIP/Foreign postal code</span> : {companyItem.applicantCurrentZipcode || ""}
                                          </div>
                                        </div>
                                        <hr />
                                        <h6>Form of identification and issuing jurisdiction</h6>
                                        <div className="text-primary">
                                          <div>
                                            <span className="label">Identifying document type </span> : {companyItem.applicantDocumentIdType || ""}
                                          </div>
                                          <div>
                                            <span className="label">Identifying document number</span> : {companyItem.applicantDocumentIdNumber || ""}
                                          </div>
                                        </div>
                                          <hr />
                                          <h6>Identifying document issuing jurisdiction</h6>
                                          <div className="text-primary">
                                          <div>
                                            <span className="label">a. Country/Jurisdiction</span> : {companyItem.applicantDocumentIdIssuingCountryJurisdiction || ""}
                                          </div>
                                          <div>
                                            <span className="label">b. State</span> : {companyItem.applicantDocumentIdIssuingState || ""}
                                          </div>
                                          <div>
                                            <span className="label">c. Local/Tribal</span> : {companyItem.applicantDocumentIdIssuingLocalTribal || ""}
                                          </div>
                                          <div>
                                            <span className="label">d. Other local/Tribal description</span> : {companyItem.applicantDocumentIdIssuingLocalOtherTribe || ""}
                                          </div>
                                        </div>
                                      </Accordion.Body>
                                    </Accordion.Item>
                                  ))}
                                </Accordion>
                                :
                                entityItem.entity_data}
                            </>
                        }
                      </div>
                    </>}
                  </div>}
                </>)
              })}
            </div>}
          </div>)
        })}
      </section>)
    })}
  </>);
};

export default ReviewEdit;
