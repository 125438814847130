import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Button, Form, Accordion } from "react-bootstrap";
import axios from "axios";
import Autocomplete from "react-google-autocomplete";
const serverConfig = require("../config/server.js");

const CompanyApplicant = forwardRef(
  (
    {
      handleShowInfoModal,
      inputs,
      setInputs,
      //handleOptions,
      countryLookup,
      localTribalLookup,
      groupData,
    },
    ref
  ) => {
    const emptyCompanyApplicantJson = {
      applicantTitle: "",
      applicantSuffix: "",
      applicantFincenId: "",
      applicantFirstName: "",
      applicantMiddleName: "",
      applicantAddressType: "",
      applicantDateOfBirth: "",
      applicantCurrentCity: "",
      applicantCurrentState: "",
      applicantCurrentAddress: "",
      applicantDocumentIdType: "",
      applicantCurrentZipcode: "",
      applicantCurrentStateCode: "",
      applicantDocumentIdNumber: "",
      applicantIndividualLastName: "",
      applicantDocumentIdIssuingState: "",
      applicantDocumentIdIssuingStateCode: "",
      applicantIdentifyingDocumentImage: "",
      applicantCurrentCountryJurisdiction: "",
      applicantCurrentCountryJurisdictionCode: "",
      applicantDocumentIdIssuingLocalTribal: "",
      applicantDocumentIdIssuingLocalTribalCode: "",
      applicantIdentifyingDocumentImageBase64: "",
      applicantDocumentIdIssuingLocalOtherTribe: "",
      applicantDocumentIdIssuingCountryJurisdiction: "",
      applicantDocumentIdIssuingCountryJurisdictionCode: "",
    };

    const [companyApplicant, setCompanyApplicant] = useState([
      emptyCompanyApplicantJson,
    ]);
    const [stateLookup, setStateLookup] = useState([]);
    const [cityLookup, setCityLookup] = useState([]);
    const [activeKey, setActiveKey] = useState("0");

    useEffect(() => {
      //console.log(sessionStorage.getItem('token'));
      console.log(inputs, groupData);
      let companyApplicantInfo = groupData.group_sections.filter(
        (section) => section.section_name === "company_applicant_information"
      );
      console.log(companyApplicantInfo);
      if (companyApplicantInfo.length > 0) {
        let applicant = companyApplicantInfo[0].section_entities.filter(
          (entities) => entities.entity_name === "companyApplicantInformation"
        );
        console.log(JSON.parse(applicant[0].entity_data));
        if (applicant[0].entity_data) {
          setCompanyApplicant(JSON.parse(applicant[0].entity_data));
        } else {
          setCompanyApplicant([emptyCompanyApplicantJson]);
          setInputs({...inputs, companyApplicantInformation: [emptyCompanyApplicantJson]});
        }
      }
    }, [groupData]);

    useImperativeHandle(ref, () => ({
      handleAddCompanyApplicant() {
        setCompanyApplicant([...companyApplicant, emptyCompanyApplicantJson]);
        var id = companyApplicant.length + 1;
        setTimeout(() => {
          setActiveKey(`${companyApplicant.length}`);
          setTimeout(() => {
            window.scrollTo(
              0,
              document.getElementById("applicant" + id)?.offsetTop
            );
          }, 1000);
        });
      },
    }));

    /* const handleAddCompanyApplicant = () => {
    setCompanyApplicant([...companyApplicant, emptyCompanyApplicantJson]);
  } */

    const handleRemoveCompanyApplicant = (event, index) => {
      console.log(index);
      let arr = companyApplicant;
      console.log(companyApplicant);

      if (index > -1) {
        // only splice array when item is found
        arr.splice(index, 1); // 2nd parameter means remove one item only
      }

      console.log(arr);
      setCompanyApplicant([]);
      setTimeout(() => {
        console.log(arr);
        setCompanyApplicant(arr);
        setInputs({ ...inputs, companyApplicantInformation: arr });
        if (companyApplicant.length === 0) {
          setCompanyApplicant([""]);
          setInputs({ ...inputs, companyApplicantInformation: [""] });
        }
      });
    };
    
    const handleCompanyApplicantInputChange = (event, index, entityDataType) => {
      const name = event.target.name.split('-')[0];
      let value = event.target.value;
    
      console.log("Changed Value:", value, "Entity Type:", entityDataType);
    
      // Clone the state to avoid direct mutation
      const updatedApplicants = [...companyApplicant];
      const currentApplicant = { ...updatedApplicants[index] };
    
      if (event.target.type === "checkbox") {
        currentApplicant[name] = event.target.checked ? value : "";
      } else if (event.target.type === "file") {
        const extension = value.split(".").pop();
        if (event.target.accept === ".pdf, .jpeg, .jpg, .png") {
          if (["pdf", "jpeg", "jpg", "png"].includes(extension)) {
            const file = document.querySelector(`#${event.target.id}`).files[0];
            const fileSizeInMB = file.size / 1048576;
            if (fileSizeInMB <= 4) {
              const reader = new FileReader();
              reader.onload = function () {
                currentApplicant[name] = file.name;
                currentApplicant[`${name}Base64`] = reader.result;
                updatedApplicants[index] = currentApplicant;
                setCompanyApplicant(updatedApplicants);
              };
              reader.readAsDataURL(file);
            } else {
              alert("File size exceeds maximum limit of 4MB. Please try again.");
              event.target.value = "";
            }
          } else {
            alert("Only .pdf, .jpeg, .jpg, .png file is allowed.");
            event.target.value = "";
          }
        }
      } else {
        currentApplicant[name] = value;
    
        // Additional logic for country/state/local_tribal handling
        if (entityDataType === "country" || entityDataType === "state" || entityDataType === "local_tribal") {
          const options = document.querySelectorAll(`#${name}${index + 1} option`);
          options.forEach((option) => {
            if (option.innerText === value) {
              currentApplicant[`${name}Code`] = option.getAttribute("data-value");
            }
          });
    
          // Fetch dependent data for country/state if needed
          if (entityDataType === "country") {
            axios
              .post(serverConfig.api_base_url + "get_lookup_values", {
                entity_name: "state",
                entity_parent_value: value,
              })
              .then((response) => {
                setStateLookup(response.data.data.sort((a, b) => a.value.localeCompare(b.value)));
              })
              .catch((err) => console.error("Error fetching state data:", err));
          }
    
          if (entityDataType === "state") {
            axios
              .post(serverConfig.api_base_url + "get_lookup_values", {
                entity_name: "city",
                entity_parent_value: value,
              })
              .then((response) => {
                setCityLookup(response.data.data.sort((a, b) => a.value.localeCompare(b.value)));
              })
              .catch((err) => console.error("Error fetching city data:", err));
          }
        }
      }
    
      // Update the state
      updatedApplicants[index] = currentApplicant;
      setCompanyApplicant(updatedApplicants);
    
      // Sync with `inputs` state if needed
      setInputs((prevInputs) => ({
        ...prevInputs,
        companyApplicantInformation: updatedApplicants,
      }));
    };
    
    const handleOptions = (event, index, entityDataType) => {
      const name = event.target.name;
      const value = event.target.value;
      const list = event.target.getAttribute('list');
      const label = event.target.parentElement.querySelector('label').innerText.replace('*','');
  
      if(!Array.from(document.querySelector('#'+list).options).map(o => o.innerText).includes(value)){
        if(value !== ''){
          console.log(value);
          alert('Entered '+ label +' option does not exist');

          const updatedApplicants = [...companyApplicant];
          const currentApplicant = { ...updatedApplicants[index] };
          currentApplicant[name] = '';
        
          // Additional logic for country/state/local_tribal handling
          if (entityDataType === "country" || entityDataType === "state" || entityDataType === "local_tribal") {
            const options = document.querySelectorAll(`#${name}${index + 1} option`);
            options.forEach((option) => {
              if (option.innerText === value) {
                currentApplicant[`${name}Code`] = '';
              }
            });
          }

          updatedApplicants[index] = currentApplicant;
          setCompanyApplicant(updatedApplicants);
        
          // Sync with `inputs` state if needed
          setInputs((prevInputs) => ({
            ...prevInputs,
            companyApplicantInformation: updatedApplicants,
          }));
        }
      }
    }

    const handleDelete = (event) => {
      console.log("handleDelete");
      var key = event.keyCode || event.charCode;
      let custDataType = event.target.dataset.customDatatype;

      if (key == 8 || key == 46) {
        if (event.target.value === "") {
          //setInputs(values => ({...values, [event.target.name]: event.target.value}))
        }
      } else if (
        !(key >= 48 && key <= 57) &&
        key !== 9 &&
        key !== 13 &&
        custDataType === "amount"
      ) {
        event.preventDefault();
      }

      if (event.target.type === "number") {
        if (["e", "E", "+", "-"].includes(event.key)) {
          event.preventDefault();
        }
      }
    };

    //auto populate address
    const handlePlaceSelectedCA = (place, index) => {
      const Address = {};
      console.log(inputs, "qq", companyApplicant);
      console.log({ ...inputs });
      const newData = { ...inputs };
      console.log(newData);
      console.log(place);
      let placeArr = place.address_components;
      console.log(placeArr);
      let country = placeArr.filter((el) => el.types.includes("country"));
      if (country.length > 0) {
        console.log("country", country[0].long_name);
        //document.getElementsByName('country')[0].value = country[0].long_name;
        Address["country"] = country[0].long_name;
        Address["countryCode"] = country[0].short_name;
      }
      let state = placeArr.filter((el) =>
        el.types.includes("administrative_area_level_1")
      );
      if (state.length > 0) {
        console.log("state", state.length);
        //document.getElementsByName('state')[0].value = state[0].long_name;
        Address["state"] = state[0].long_name;
        Address["stateCode"] = state[0].short_name;
      }
      let city = placeArr.filter((el) => el.types.includes("locality"));
      if (city.length > 0) {
        console.log(city.length, "CITY");
        console.log("city", city[0].long_name);
        //document.getElementsByName('city')[0].value = city[0].long_name;
        Address["city"] = city[0].long_name;
      }
      let postalCode = placeArr.filter((el) =>
        el.types.includes("postal_code")
      );
      if (postalCode.length > 0) {
        console.log("postalCode", postalCode[0].long_name);
        //document.getElementsByName('zipcode')[0].value = postalCode[0].long_name;
        Address["zipcode"] = postalCode[0].long_name;
      }
      let addressLine2 = "";
      placeArr.some((el) => {
        if (el.types.includes("locality")) {
          //console.log('yes, I entered the if statement')
          return true;
        } else {
          if (el.long_name) {
            console.log(el.long_name);
            addressLine2 += el.long_name + ", ";
            Address["addressLine2"] = addressLine2;
          }
        }
      });
      console.log(addressLine2);
      //console.log(document.getElementsByName('addressLine1')[0].value);
      //console.log((document.getElementsByName('addressLine1')[0].value).split(',')[0]);
      //document.getElementsByName('addressLine1')[0].value = (document.getElementsByName('addressLine1')[0].value).split(',')[0];
      //document.getElementsByName('addressLine2')[0].value = addressLine2;

      //Address['addressLine1']=(document.getElementsByName('addressUS')[0].value).split(',')[0];
      Address["placeId"] = place.place_id;
      console.log(Address, "adress", companyApplicant);
      /* companyApplicant[index]["applicantCurrentCountryJurisdiction"] = Address.country;
      companyApplicant[index]["applicantCurrentAddress"] = Address.addressLine2;
      companyApplicant[index]["applicantCurrentState"] = Address.state;
      companyApplicant[index]["applicantCurrentStateCode"] = Address.stateCode;
      companyApplicant[index]["applicantCurrentCity"] = Address.city;
      companyApplicant[index]["applicantCurrentZipcode"] = Address.zipcode; */

      //setAddress(Address);
      //console.log(address, 'PERMANENT_ADDRESS');

      //setCompanyApplicant(companyApplicant);
      console.log(companyApplicant);
      /* setCompanyApplicant((stateCompanyApplicant) => {
        console.log(stateCompanyApplicant);
        newData["companyApplicantInformation"] = stateCompanyApplicant;
        return stateCompanyApplicant;
      }); */


      setCompanyApplicant(el => [...el]);
      setCompanyApplicant((stateCompanyApplicant) => {
        console.log(stateCompanyApplicant);

        stateCompanyApplicant[index]["applicantCurrentCountryJurisdiction"] = Address.country;
        stateCompanyApplicant[index]["applicantCurrentCountryJurisdictionCode"] = Address.countryCode;
        stateCompanyApplicant[index]["applicantCurrentAddress"] = Address.addressLine2;
        stateCompanyApplicant[index]["applicantCurrentState"] = Address.state;
        stateCompanyApplicant[index]["applicantCurrentStateCode"] = Address.stateCode;
        stateCompanyApplicant[index]["applicantCurrentCity"] = Address.city;
        stateCompanyApplicant[index]["applicantCurrentZipcode"] = Address.zipcode;
        stateCompanyApplicant[index]['applicantCurrentAddressPlaceId'] = Address.placeId;

        console.log(stateCompanyApplicant);
        newData["companyApplicantInformation"] = stateCompanyApplicant;

        return stateCompanyApplicant;
      });

      console.log(newData);
      setInputs(newData);

      //console.log('aaa', inputs, 'qq', companyApplicant);
    };

    return (
      <div>
        {/* <div className="d-flex gap-1 justify-content-between align-items-center">
        <h5 className="mb-0">Beneficial owner(s)</h5>
        <Button type="button" variant="text" onClick={handleAddCompanyApplicant}>+ <u>Add beneficial owner</u></Button>
      </div> */}
        <Accordion activeKey={activeKey}>
          {companyApplicant.length > 0 &&
            companyApplicant.map((item, index) => {
              return (
                <>
                  <Accordion.Item
                    eventKey={`${index}`}
                    id={"applicant" + (index + 1)}
                    className="mb-3"
                  >
                    <div className="d-flex gap-1">
                      <Accordion.Header
                        className="flex-grow-1"
                        onClick={() =>
                          setActiveKey(
                            activeKey === `${index}` ? null : `${index}`
                          )
                        }
                      >
                        Company Applicant #{index + 1}
                      </Accordion.Header>
                      {companyApplicant.length !== 1 && (
                        <Button
                          type="button"
                          variant="transparent"
                          onClick={(e) =>
                            handleRemoveCompanyApplicant(e, index)
                          }
                        >
                          &times;
                        </Button>
                      )}
                    </div>
                    <Accordion.Body>
                      <section>
                        <fieldset
                          className="d-block"
                        >
                          <div className="d-flex align-items-start gap-2 mt-3 mb-2">
                            <h5>Identifying document</h5>
                            <Button
                              type="button"
                              variant="transparent"
                              className="info-help-btn border-0 p-0 ms-auto"
                              onClick={() =>
                                handleShowInfoModal(
                                  "Identifying document",
                                  "<div><p>This is the document captured in Documentation in previous step <br/> <br/> Drag and drop another file or click to attach another file - a clear, readable image of the page or side of the identifying document referenced in Identifying document number field containing the unique identifying number and other identifying data. An attachment to a BOIR cannot be larger than four (4) megabytes of data and must be in one of the following formats: JPG/JPEG, PNG, or PDF. Only one (1) attachment file may be added per beneficial owner. Attachment file names should not contain spaces, and can only contain letters, numbers, or the following characters !@#$%()_-.=+[]|;~</p></div>"
                                )
                              }
                            >
                              <img
                                src={require("./../assets/images/help-circle.png")}
                                alt="Help Text"
                                className="img-fluid"
                              />
                            </Button>
                          </div>
                          <div className="mb-4">
                            <div className="position-relative">
                              <div className="position-relative">
                                <Form.Label
                                  htmlFor={
                                    "applicantIdentifyingDocumentImage" +
                                    (index + 1)
                                  }
                                  className="upload"
                                >
                                  {item.applicantIdentifyingDocumentImage ||
                                  document.getElementById(
                                    "applicantIdentifyingDocumentImage" +
                                      (index + 1)
                                  )?.files[0]?.name ? (
                                    <div className="d-flex gap-1 justify-content-between align-items-center">
                                      <b>
                                        {document.getElementById(
                                          "applicantIdentifyingDocumentImage" +
                                            (index + 1)
                                        )?.files[0]?.name ||
                                          item.applicantIdentifyingDocumentImage}
                                      </b>
                                      Tap to upload again
                                    </div>
                                  ) : (
                                    <>
                                      Drag and Drop the file here or{" "}
                                      <u>Browse</u>. <br />
                                      [Max 4MB]
                                    </>
                                  )}
                                </Form.Label>
                                <Form.Control
                                  id={
                                    "applicantIdentifyingDocumentImage" +
                                    (index + 1)
                                  }
                                  name="applicantIdentifyingDocumentImage"
                                  type="file"
                                  accept=".pdf, .jpeg, .jpg, .png"
                                  onChange={(e) =>
                                    handleCompanyApplicantInputChange(
                                      e,
                                      index,
                                      undefined
                                    )
                                  }
                                  required={
                                    item.applicantFincenId === "" &&
                                    item.applicantIdentifyingDocumentImage ===
                                      ""
                                      ? true
                                      : false
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </fieldset>
                      </section>

                      <section>
                        <fieldset
                          className={
                            "d-block" //fincen ID
                          }
                        >
                          <div className="d-flex align-items-start gap-2 mt-3 mb-2">
                            <h5>Company applicant FinCEN ID</h5>
                            <Button
                              type="button"
                              variant="transparent"
                              className="info-help-btn border-0 p-0 ms-auto"
                              onClick={() =>
                                handleShowInfoModal(
                                  "Company applicant FinCEN ID",
                                  "<div><p>Reporting companies may report the FinCEN ID for a company applicant instead of the information in <b>Legal name and date of birth</b> section.</p><p>Enter the FinCEN ID as a single text string. If a FinCEN ID for the company applicant is not provided, information about the company applicant must be provided in <b>Legal name and date of birth</b> section.</p></div>"
                                )
                              }
                            >
                              <img
                                src={require("./../assets/images/help-circle.png")}
                                alt="Help Text"
                                className="img-fluid"
                              />
                            </Button>
                          </div>
                          <div className="mb-4">
                            <div className="d-flex align-items-start gap-2">
                              <label className="form-label">
                                <span>FinCEN ID</span>{" "}
                              </label>
                            </div>
                            <Form.Control
                              name="applicantFincenId"
                              value={item.applicantFincenId || ""}
                              onChange={(e) =>
                                handleCompanyApplicantInputChange(
                                  e,
                                  index,
                                  undefined
                                )
                              }
                              onKeyDown={handleDelete}
                              type="text"
                              pattern="[0-9]{12}"
                              title="Must be 12 numeric characters"
                            />
                          </div>
                        </fieldset>
                      </section>
                      <section>
                        <fieldset
                          className={
                            item.applicantFincenId !== "" ? "d-none" : "d-block"
                          }
                          disabled={
                            item.applicantFincenId !== "" ? true : false
                          }
                        >
                          <div className="d-flex align-items-start gap-2 mt-3 mb-2">
                            <h5>Legal name and date of birth</h5>
                            <Button
                              type="button"
                              variant="transparent"
                              className="info-help-btn border-0 p-0 ms-auto"
                              onClick={() =>
                                handleShowInfoModal(
                                  "Legal name and date of birth",
                                  "<div><h5>Last name</h5><p>Enter the company applicant's legal last name.</p><h5>First name</h5><p>Enter the company applicant's legal first name.</p><h5>Middle name</h5><p>Enter the company applicant's middle name if the company applicant's legal name has a middle name. Leave this item blank if the company applicant does not have a middle name.</p><h5>Suffix</h5><p>Enter the company applicant's suffix such as JR, SR, III, etc., if the company applicant has a suffix to their legal name. Leave this item blank if the company applicant's legal name does not include a suffix.</p><h5>Date of birth</h5><p>Enter the company applicant's date of birth using the format MM/DD/YYYY where MM = month, DD = day, and YYYY = year (e.g., 01/25/1970). The month, day, and year must be provided; no partial dates are accepted.</p><h5>Title</h5><p>Enter the company applicant's role in the company.</p></div>"
                                )
                              }
                            >
                              <img
                                src={require("./../assets/images/help-circle.png")}
                                alt="Help Text"
                                className="img-fluid"
                              />
                            </Button>
                          </div>
                          <div className="mb-4">
                            <div className="d-flex align-items-start gap-2">
                              <label className="form-label">
                                <span>Individual's last name</span>{" "}
                                {(item.applicantFincenId === null ||
                                  item.applicantFincenId === "") && (
                                  <span className="txt-red">*</span>
                                )}
                              </label>
                            </div>
                            <Form.Control
                              name="applicantIndividualLastName"
                              value={item.applicantIndividualLastName || ""}
                              onChange={(e) =>
                                handleCompanyApplicantInputChange(
                                  e,
                                  index,
                                  undefined
                                )
                              }
                              onKeyDown={handleDelete}
                              type="text"
                              required={
                                item.applicantFincenId === null ||
                                item.applicantFincenId === ""
                              }
                            />
                          </div>
                          <fieldset
                            className={
                              item.applicantFincenId !== "" 
                                ? "d-none"
                                : "d-block"
                            }
                            disabled={
                              item.applicantFincenId !== "" 
                                ? true
                                : false
                            }
                          >
                            <div className="mb-4">
                              <div className="d-flex align-items-start gap-2">
                                <label className="form-label">
                                  <span>First name</span>{" "}
                                  {(item.applicantFincenId === null ||
                                    item.applicantFincenId === "") && (
                                    <span className="txt-red">*</span>
                                  )}
                                </label>
                              </div>
                              <Form.Control
                                name="applicantFirstName"
                                value={item.applicantFirstName || ""}
                                onChange={(e) =>
                                  handleCompanyApplicantInputChange(
                                    e,
                                    index,
                                    undefined
                                  )
                                }
                                onKeyDown={handleDelete}
                                type="text"
                                required={
                                  item.applicantFincenId === null ||
                                  item.applicantFincenId === ""
                                }
                              />
                            </div>
                            <div className="mb-4">
                              <div className="d-flex align-items-start gap-2">
                                <label className="form-label">
                                  <span>Middle name</span>{" "}
                                </label>
                              </div>
                              <Form.Control
                                name="applicantMiddleName"
                                value={item.applicantMiddleName || ""}
                                onChange={(e) =>
                                  handleCompanyApplicantInputChange(
                                    e,
                                    index,
                                    undefined
                                  )
                                }
                                onKeyDown={handleDelete}
                                type="text"
                              />
                            </div>
                            <div className="mb-4">
                              <div className="d-flex align-items-start gap-2">
                                <label className="form-label">
                                  <span>Suffix</span>{" "}
                                </label>
                              </div>
                              <Form.Control
                                name="applicantSuffix"
                                value={item.applicantSuffix || ""}
                                onChange={(e) =>
                                  handleCompanyApplicantInputChange(
                                    e,
                                    index,
                                    undefined
                                  )
                                }
                                onKeyDown={handleDelete}
                                type="text"
                              />
                            </div>
                            <div className="mb-4">
                              <div className="d-flex align-items-start gap-2">
                                <label className="mr-3 form-label">
                                  <span>Date of birth</span>{" "}
                                  {(item.applicantFincenId === null ||
                                    item.applicantFincenId === "") && (
                                    <span className="txt-red">*</span>
                                  )}
                                </label>
                              </div>
                              <Form.Control
                                name="applicantDateOfBirth"
                                value={item.applicantDateOfBirth || ""}
                                onChange={(e) =>
                                  handleCompanyApplicantInputChange(
                                    e,
                                    index,
                                    undefined
                                  )
                                }
                                onKeyDown={handleDelete}
                                type="date"
                                required={
                                  item.applicantFincenId === null ||
                                  item.applicantFincenId === ""
                                }
                                max={new Date().toISOString().split("T")[0]}
                              />
                            </div>
                            <div className="mb-4">
                              <div className="d-flex align-items-start gap-2">
                                <label className="form-label">
                                  <span>Job Title</span>{" "}
                                  {(item.applicantFincenId === null ||
                                    item.applicantFincenId === "") && (
                                    <span className="txt-red">*</span>
                                  )}
                                </label>
                              </div>
                              <Form.Control
                                name="applicantTitle"
                                value={item.applicantTitle || ""}
                                onChange={(e) =>
                                  handleCompanyApplicantInputChange(
                                    e,
                                    index,
                                    undefined
                                  )
                                }
                                onKeyDown={handleDelete}
                                type="text"
                                required={
                                  item.applicantFincenId === null ||
                                  item.applicantFincenId === ""
                                }
                              />
                            </div>
                          </fieldset>
                        </fieldset>
                      </section>
                      <section>
                        <fieldset
                          className={
                            item.applicantFincenId !== ""
                              ? "d-none"
                              : "d-block"
                          }
                          disabled={
                            item.applicantFincenId !== "" 
                              ? true
                              : false
                          }
                        >
                          <div className="d-flex align-items-start gap-2 mt-3 mb-2">
                            <h5>Current address</h5>
                            <Button
                              type="button"
                              variant="transparent"
                              className="info-help-btn border-0 p-0 ms-auto"
                              onClick={() =>
                                handleShowInfoModal(
                                  "Current address",
                                  "<div><h5>Address type</h5><p>Indicate address type as “Business address” or “Residential address” for the company applicant. For a company applicant who forms or registers an entity in the course of their business, such as paralegals, report the street address of such business. In any other case, the individual's residential street address must be reported.</p><h5>Company applicant address</h5><p>Enter the company applicant's street address information, including the city, country or jurisdiction, State, and ZIP code or foreign postal code. U.S. Territories are included in the drop-down menu for “Country/Jurisdiction.” The “State” will be automatically populated when a U.S. Territory is selected in the “Country/Jurisdiction”. “State” field is required if selected country  is the United States, Canada, or Mexico.</p></div>"
                                )
                              }
                            >
                              <img
                                src={require("./../assets/images/help-circle.png")}
                                alt="Help Text"
                                className="img-fluid"
                              />
                            </Button>
                          </div>
                          <div className="mb-4">
                            <div className="d-flex align-items-start gap-2">
                              <label className="form-label">
                                <span>Address type</span>{" "}
                                <span className="txt-red">*</span>
                              </label>
                            </div>
                            <div className="d-flex flex-column">
                              <Form.Check
                                inline
                                type="radio"
                                name={`applicantAddressType-${index}`}
                                id={`applicantAddressTypeBusiness-${index + 1}`}
                                label="Business Address"
                                value="Business Address"
                                checked={item.applicantAddressType === "Business Address"}
                                onChange={(e) => handleCompanyApplicantInputChange(e, index, "addressType")}
                              />
                              <Form.Check
                                inline
                                type="radio"
                                name={`applicantAddressType-${index}`}
                                id={`applicantAddressTypeResidential-${index + 1}`}
                                label="Residential Address"
                                value="Residential Address"
                                checked={item.applicantAddressType === "Residential Address"}
                                onChange={(e) => handleCompanyApplicantInputChange(e, index, "addressType")}
                              />
                            </div>
                          </div>
                          <div className="mb-4">
                            <div className="d-flex align-items-start gap-2">
                              <label className="mr-3 form-label">
                                <span>Country/Jurisdiction</span>{" "}
                                {(item.applicantFincenId === null ||
                                  item.applicantFincenId === "") && (
                                  <span className="txt-red">*</span>
                                )}
                              </label>
                            </div>
                            <Form.Control
                              name="applicantCurrentCountryJurisdiction"
                              value={
                                item.applicantCurrentCountryJurisdiction || ""
                              }
                              onBlur={(e) => handleOptions(e, index, "country")}
                              onChange={(e) =>
                                handleCompanyApplicantInputChange(
                                  e,
                                  index,
                                  "country"
                                )
                              }
                              type="text"
                              required={
                                item.applicantFincenId === null ||
                                item.applicantFincenId === ""
                              }
                              list={
                                "applicantCurrentCountryJurisdiction" +
                                (index + 1)
                              }
                            />
                            <datalist
                              id={
                                "applicantCurrentCountryJurisdiction" +
                                (index + 1)
                              }
                            >
                              <option></option>
                              {countryLookup &&
                                countryLookup.map((item, index) => (
                                  <option
                                    value={item.value}
                                    key={index}
                                    data-value={item.country_code}
                                  >
                                    {item.value}
                                  </option>
                                ))}
                            </datalist>
                          </div>
                          <div className="mb-4">
                            <div className="d-flex align-items-start gap-2">
                              <label className="form-label">
                                <span>
                                  Address (number, street, and apt. or suite
                                  no.)
                                </span>{" "}
                                {(item.applicantFincenId === null ||
                                  item.applicantFincenId === "") && (
                                  <span className="txt-red">*</span>
                                )}
                              </label>
                            </div>
                            {/* <Form.Control 
                        name="applicantCurrentAddress" 
                        value={item.applicantCurrentAddress || ""}
                        onChange={(e) => handleCompanyApplicantInputChange(e, index, undefined)} 
                        onKeyDown={handleDelete} 
                        type="text" 
                        required={item.applicantFincenId === null || item.applicantFincenId === ''}
                      /> */}
                            <Autocomplete
                              apiKey="AIzaSyBafPK5TmCxMIAXCroevkGly1srtn6WB8s"
                              onPlaceSelected={(place) => {
                                handlePlaceSelectedCA(place, index);
                              }}
                              options={{ types: ["geocode", "establishment"] }}
                              name="applicantCurrentAddress"
                              required={
                                item.applicantFincenId === null ||
                                item.applicantFincenId === ""
                              }
                              className="form-control"
                              placeholder=""
                              value={item.applicantCurrentAddress || ""}
                              onChange={(e) =>
                                handleCompanyApplicantInputChange(
                                  e,
                                  index,
                                  undefined
                                )
                              }
                              onKeyDown={handleDelete}
                            />
                          </div>
                          <div className="mb-4">
                            <div className="d-flex align-items-start gap-2">
                              <label className="mr-3 form-label">
                                <span>State</span>{" "}
                                {(item.applicantFincenId === null ||
                                  item.applicantFincenId === "") && (
                                  <span className="txt-red">*</span>
                                )}
                              </label>
                            </div>
                            <Form.Control
                              name="applicantCurrentState"
                              value={item.applicantCurrentState || ""}
                              onBlur={(e) => handleOptions(e, index, "state")}
                              onChange={(e) =>
                                handleCompanyApplicantInputChange(
                                  e,
                                  index,
                                  "state"
                                )
                              }
                              type="text"
                              required={
                                item.applicantFincenId === null ||
                                item.applicantFincenId === ""
                              }
                              readOnly={
                                item.applicantCurrentCountryJurisdiction ===
                                  "American Samoa" ||
                                item.applicantCurrentCountryJurisdiction ===
                                  "Guam" ||
                                item.applicantCurrentCountryJurisdiction ===
                                  "Marshall Islands" ||
                                item.applicantCurrentCountryJurisdiction ===
                                  "Micronesia" ||
                                item.applicantCurrentCountryJurisdiction ===
                                  "Northern Mariana Islands" ||
                                item.applicantCurrentCountryJurisdiction ===
                                  "Palau" ||
                                item.applicantCurrentCountryJurisdiction ===
                                  "Puerto Rico" ||
                                item.applicantCurrentCountryJurisdiction ===
                                  "Virgin Islands (US)"
                              }
                              list={"applicantCurrentState" + (index + 1)}
                            />
                            <datalist
                              id={"applicantCurrentState" + (index + 1)}
                            >
                              <option></option>
                              {stateLookup &&
                                stateLookup.map((item, index) => (
                                  <option
                                    value={item.value}
                                    key={index}
                                    data-value={item.state_code}
                                  >
                                    {item.value}
                                  </option>
                                ))}
                            </datalist>
                          </div>
                          <div className="mb-4">
                            <div className="d-flex align-items-start gap-2">
                              <label className="mr-3 form-label">
                                <span>City</span>{" "}
                                {(item.applicantFincenId === null ||
                                  item.applicantFincenId === "") && (
                                  <span className="txt-red">*</span>
                                )}
                              </label>
                            </div>
                            <Form.Control
                              name="applicantCurrentCity"
                              value={item.applicantCurrentCity || ""}
                              onChange={(e) =>
                                handleCompanyApplicantInputChange(
                                  e,
                                  index,
                                  undefined
                                )
                              }
                              type="text"
                              required={
                                item.applicantFincenId === null ||
                                item.applicantFincenId === ""
                              }
                              list={"applicantCurrentCity" + (index + 1)}
                            />
                            <datalist id={"applicantCurrentCity" + (index + 1)}>
                              <option></option>
                              {cityLookup &&
                                cityLookup.map((item, index) => (
                                  <option value={item.value} key={index}>
                                    {item.value}
                                  </option>
                                ))}
                            </datalist>
                          </div>
                          <div className="mb-4">
                            <div className="d-flex align-items-start gap-2">
                              <label className="form-label">
                                <span>ZIP/Foreign postal code</span>{" "}
                                {(item.applicantFincenId === null ||
                                  item.applicantFincenId === "") && (
                                  <span className="txt-red">*</span>
                                )}
                              </label>
                            </div>
                            <Form.Control
                              name="applicantCurrentZipcode"
                              value={item.applicantCurrentZipcode || ""}
                              onChange={(e) =>
                                handleCompanyApplicantInputChange(
                                  e,
                                  index,
                                  undefined
                                )
                              }
                              onKeyDown={handleDelete}
                              type="text"
                              required={
                                item.applicantFincenId === null ||
                                item.applicantFincenId === ""
                              }
                              pattern="[A-Z0-9]{5}"
                              title="Must equal 5 characters"
                            />
                          </div>
                        </fieldset>
                      </section>
                      <section>
                        <fieldset
                          className={
                            item.applicantFincenId !== ""
                              ? "d-none"
                              : "d-block"
                          }
                          disabled={
                            item.applicantFincenId !== ""
                              ? true
                              : false
                          }
                        >
                          <div className="d-flex align-items-start gap-2 mt-3 mb-2">
                            <h5>
                              Form of identification and issuing jurisdiction
                            </h5>
                            <Button
                              type="button"
                              variant="transparent"
                              className="info-help-btn border-0 p-0 ms-auto"
                              onClick={() =>
                                handleShowInfoModal(
                                  "Form of identification and issuing jurisdiction",
                                  "<div><h5>Identifying document type</h5><p>Select the company applicant's identifying document type from the list of acceptable documents: a non-expired State-issued driver's license, a non-expired State/local/Tribe-issued identification document issued for the purpose of identifying the individual, a non-expired U.S. passport, or, only if the company applicant does not have one of these identifying documents, a non-expired foreign passport.</p><h5>Identifying document number</h5><p>Enter the identifying document number from the company applicant's identifying document.</p><h5>Identifying document issuing jurisdiction</h5><p>Select country/jurisdiction that issued the company applicant's identifying document.</p><p>If a U.S. Territory issued the identifying document, select the applicable U.S. Territory (the same U.S. Territory will then be automatically populated in “State” field as a result).</p><p>Enter State issuing the identifying document when country/jurisdiction is United States, if applicable.</p><p>If a local or Tribal government issued the identifying document, select “United States” and then select the applicable local or Tribal description.</p><p>If the name of the relevant local or Tribal jurisdiction is not included in the drop-down menu, select “Other” and enter the name of the local or Tribal jurisdiction in the next field.</p></div>"
                                )
                              }
                            >
                              <img
                                src={require("./../assets/images/help-circle.png")}
                                alt="Help Text"
                                className="img-fluid"
                              />
                            </Button>
                          </div>
                          <div className="mb-4">
                            <div className="d-flex align-items-start gap-2">
                              <label className="mr-3 form-label">
                                <span>Identifying document type</span>{" "}
                                {(item.applicantFincenId === null ||
                                  item.applicantFincenId === "") && (
                                  <span className="txt-red">*</span>
                                )}
                              </label>
                            </div>
                            <Form.Select
                              name="applicantDocumentIdType"
                              value={item.applicantDocumentIdType || ""}
                              onChange={(e) =>
                                handleCompanyApplicantInputChange(
                                  e,
                                  index,
                                  undefined
                                )
                              }
                              required={
                                item.applicantFincenId === null ||
                                item.applicantFincenId === ""
                              }
                            >
                              <option value="">Select</option>
                              <option value="State issued driver's license">
                                State issued driver's license
                              </option>
                              <option value="State/local/tribe-issued ID">
                                State/local/tribe-issued ID
                              </option>
                              <option value="U.S. passport">
                                U.S. passport
                              </option>
                              <option value="Foreign passport">
                                Foreign passport
                              </option>
                            </Form.Select>
                          </div>
                          <div className="mb-4">
                            <div className="d-flex align-items-start gap-2">
                              <label className="form-label">
                                <span>Identifying document number</span>{" "}
                                {(item.applicantFincenId === null ||
                                  item.applicantFincenId === "") && (
                                  <span className="txt-red">*</span>
                                )}
                              </label>
                            </div>
                            <Form.Control
                              name="applicantDocumentIdNumber"
                              value={item.applicantDocumentIdNumber || ""}
                              onChange={(e) =>
                                handleCompanyApplicantInputChange(
                                  e,
                                  index,
                                  undefined
                                )
                              }
                              onKeyDown={handleDelete}
                              type="text"
                              required={
                                item.applicantFincenId === null ||
                                item.applicantFincenId === ""
                              }
                            />
                          </div>
                          <div className="mb-4">
                            <div className="d-flex align-items-start gap-2">
                              <label className="form-label">
                                <span>
                                  Identifying document issuing jurisdiction
                                </span>{" "}
                              </label>
                            </div>
                          </div>
                          <div className="mb-4">
                            <div className="d-flex align-items-start gap-2">
                              <label className="mr-3 form-label">
                                <span>a. Country/Jurisdiction</span>{" "}
                                {(item.applicantFincenId === null ||
                                  item.applicantFincenId === "") && (
                                  <span className="txt-red">*</span>
                                )}
                              </label>
                            </div>
                            <Form.Control
                              name="applicantDocumentIdIssuingCountryJurisdiction"
                              value={
                                item.applicantDocumentIdIssuingCountryJurisdiction ||
                                ""
                              }
                              onBlur={(e) => handleOptions(e, index, "country")}
                              onChange={(e) =>
                                handleCompanyApplicantInputChange(
                                  e,
                                  index,
                                  "country"
                                )
                              }
                              type="text"
                              required={
                                item.applicantFincenId === null ||
                                item.applicantFincenId === ""
                              }
                              readOnly={
                                item.applicantDocumentIdType === "U.S. passport"
                              }
                              list={
                                "applicantDocumentIdIssuingCountryJurisdiction" +
                                (index + 1)
                              }
                            />
                            <datalist
                              id={
                                "applicantDocumentIdIssuingCountryJurisdiction" +
                                (index + 1)
                              }
                            >
                              <option></option>
                              {countryLookup &&
                                countryLookup.map((item, index) => (
                                  <option
                                    value={item.value}
                                    key={index}
                                    data-value={item.country_code}
                                  >
                                    {item.value}
                                  </option>
                                ))}
                            </datalist>
                          </div>
                          <div className="mb-4">
                            <div className="d-flex align-items-start gap-2">
                              <label className="mr-3 form-label">
                                <span>b. State</span>
                                {(item.applicantDocumentIdType ===
                                  "State issued driver's license" ||
                                  (item.applicantDocumentIdType ===
                                    "State/local/tribe-issued ID" &&
                                    (item.applicantDocumentIdIssuingLocalTribal ===
                                      null ||
                                      item.applicantDocumentIdIssuingLocalTribal ===
                                        ""))) && <span className="txt-red">*</span>}
                              </label>
                            </div>
                            <Form.Control
                              name="applicantDocumentIdIssuingState"
                              value={item.applicantDocumentIdIssuingState || ""}
                              onBlur={(e) => handleOptions(e, index, "state")}
                              onChange={(e) =>
                                handleCompanyApplicantInputChange(
                                  e,
                                  index,
                                  "state"
                                )
                              }
                              type="text"
                              required={
                                item.applicantDocumentIdType ===
                                  "State issued driver's license" ||
                                (item.applicantDocumentIdType ===
                                  "State/local/tribe-issued ID" &&
                                  (item.applicantDocumentIdIssuingLocalTribal ===
                                    null ||
                                    item.applicantDocumentIdIssuingLocalTribal ===
                                      ""))
                              }
                              readOnly={
                                item.applicantDocumentIdIssuingCountryJurisdiction ===
                                  "American Samoa" ||
                                item.applicantDocumentIdIssuingCountryJurisdiction ===
                                  "Guam" ||
                                item.applicantDocumentIdIssuingCountryJurisdiction ===
                                  "Marshall Islands" ||
                                item.applicantDocumentIdIssuingCountryJurisdiction ===
                                  "Micronesia" ||
                                item.applicantDocumentIdIssuingCountryJurisdiction ===
                                  "Northern Mariana Islands" ||
                                item.applicantDocumentIdIssuingCountryJurisdiction ===
                                  "Palau" ||
                                item.applicantDocumentIdIssuingCountryJurisdiction ===
                                  "Puerto Rico" ||
                                item.applicantDocumentIdIssuingCountryJurisdiction ===
                                  "Virgin Islands (US)"
                              }
                              disabled={
                                item.applicantDocumentIdType ===
                                  "U.S. passport" ||
                                item.applicantDocumentIdType ===
                                  "Foreign passport" ||
                                (item.applicantDocumentIdType ===
                                  "State/local/tribe-issued ID" &&
                                  item.applicantDocumentIdIssuingLocalTribal !==
                                    "")
                              }
                              list={
                                "applicantDocumentIdIssuingState" + (index + 1)
                              }
                            />
                            <datalist
                              id={
                                "applicantDocumentIdIssuingState" + (index + 1)
                              }
                            >
                              <option></option>
                              {stateLookup &&
                                stateLookup.map((item, index) => (
                                  <option
                                    value={item.value}
                                    key={index}
                                    data-value={item.state_code}
                                  >
                                    {item.value}
                                  </option>
                                ))}
                            </datalist>
                          </div>
                          <div className="mb-4">
                            <div className="d-flex align-items-start gap-2">
                              <label className="mr-3 form-label">
                                <span>c. Local/Tribal</span>
                                {item.applicantDocumentIdType ===
                                  "State/local/tribe-issued ID" &&
                                  (item.applicantDocumentIdIssuingState ===
                                    null ||
                                    item.applicantDocumentIdIssuingState ===
                                      "") && <span className="txt-red">*</span>}
                              </label>
                            </div>
                            <Form.Control
                              name="applicantDocumentIdIssuingLocalTribal"
                              value={
                                item.applicantDocumentIdIssuingLocalTribal || ""
                              }
                              onBlur={(e) => handleOptions(e, index, "local_tribal")}
                              onChange={(e) =>
                                handleCompanyApplicantInputChange(
                                  e,
                                  index,
                                  "local_tribal"
                                )
                              }
                              type="text"
                              required={
                                item.applicantDocumentIdType ===
                                  "State/local/tribe-issued ID" &&
                                (item.applicantDocumentIdIssuingState ===
                                  null ||
                                  item.applicantDocumentIdIssuingState === "")
                              }
                              disabled={
                                item.applicantDocumentIdType === "" ||
                                item.applicantDocumentIdType !==
                                  "State/local/tribe-issued ID" ||
                                (item.applicantDocumentIdType ===
                                  "State/local/tribe-issued ID" &&
                                  item.applicantDocumentIdIssuingState !== "")
                              }
                              list={
                                "applicantDocumentIdIssuingLocalTribal" +
                                (index + 1)
                              }
                            />
                            <datalist
                              id={
                                "applicantDocumentIdIssuingLocalTribal" +
                                (index + 1)
                              }
                            >
                              <option></option>
                              {localTribalLookup &&
                                localTribalLookup.map((item, index) => (
                                  <option
                                    value={item.value}
                                    key={index}
                                    data-value={item.local_tribal_code}
                                  >
                                    {item.value}
                                  </option>
                                ))}
                            </datalist>
                          </div>
                          <div className="mb-4">
                            <div className="d-flex align-items-start gap-2">
                              <label className="form-label">
                                <span>d. Other local/Tribal description</span>
                                {item.applicantDocumentIdIssuingLocalTribal ===
                                  "Other" && <span className="txt-red">*</span>}
                              </label>
                            </div>
                            <Form.Control
                              name="applicantDocumentIdIssuingLocalOtherTribe"
                              value={
                                item.applicantDocumentIdIssuingLocalOtherTribe ||
                                ""
                              }
                              onChange={(e) =>
                                handleCompanyApplicantInputChange(
                                  e,
                                  index,
                                  undefined
                                )
                              }
                              onKeyDown={handleDelete}
                              type="text"
                              required={
                                item.applicantDocumentIdIssuingLocalTribal ===
                                "Other"
                              }
                              disabled={
                                item.applicantDocumentIdIssuingLocalTribal ===
                                  "" ||
                                item.applicantDocumentIdIssuingLocalTribal !==
                                  "Other"
                              }
                            />
                          </div>
                        </fieldset>
                      </section>
                      <section></section>
                    </Accordion.Body>
                  </Accordion.Item>
                </>
              );
            })}
        </Accordion>
      </div>
    );
  }
);

export default CompanyApplicant;
