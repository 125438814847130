import React, { useEffect, useState } from 'react';
import axios from "axios";
import UserService from '../services/UserService.js';

import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';

import CreditCard from "./CreditCard";

const serverConfig = require("../config/server.js");
const appConfig = require("../config/config.js");

const stripePromise = loadStripe(appConfig.stripe_key);


const demos = [
  {
    methodName: "credit_card_elements",
    label: "Credit Card",
    icon: "credit-card-icon.png",
    component: CreditCard
  },
];

const Payment = ({token, handleLogout, curPageName, applicationId, getGroupData, getPaymentStatus, isSubscribed, isCouponCodeValid, amountAfterDiscount}) => {

  const [currency, setCurrency] = useState(null);
  const [amount, setAmount] = useState(null);

  //const[method, setMethod] = useState(null);
  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    if(currency !== null && amount !== null){
      //fetch(serverConfig.fapi_base_url + "create_payment_intent", {
      fetch(serverConfig.fapi_base_url + 'create_payment_intent', {
        method: "POST",
        body: JSON.stringify({amount:amount, currency:currency}),
      }).then(async (result) => {
        var { clientSecret } = await result.json();
        setClientSecret(clientSecret);
      });
    }
  }, [currency, amount]);

  useEffect(() => {
    if(token !== null){
      axios.post(serverConfig.api_base_url + "org_currency_get",{token: token})
      .then(response=>{
        console.log('org_currency_get', response);
        if(response.data.status === 200) {
          setCurrency(response.data.currency);
          setAmount(response.data.amount);
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));
    }
  },[token]);

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };

  const handlePaymentSet = (subscriptionID, validCoupon, amountAfterDiscount, couponCode) => {
    let formdata = {token:token, entity_group:curPageName, items:{/* isSubscribed: true, */ contactEmail:UserService.getUsername(), contactFirstName:UserService.getWelcomeName(), contactLastName:UserService.getFamilyName()/* , isCouponCodeValid:validCoupon */}, application_id:applicationId};
    axios.post(serverConfig.api_base_url + "customer_entity_group_details_set",formdata)
    .then(response=>{
      console.log('customer_entity_group_details_set response', response);
      if(response.data.status === 200) {
        console.log(currency, subscriptionID);
        axios.post(serverConfig.api_base_url + "payment_status_set",{token: token, application_id:applicationId, currency: currency, subscriptionID: subscriptionID, isCouponCodeValid:validCoupon, amountAfterDiscount: amountAfterDiscount, couponCode: couponCode})
        .then(response=>{
          console.log('payment_status_set', response);
          if(response.data.status === 200) {
            getGroupData();
            getPaymentStatus();
          } else if(response.data.status === 301){
            handleLogout();
          } else {
            alert(response.data.message);
          }
        })
        .catch(err=>console.log("error is",err));

        //getGroupData();

      } else if(response.data.status === 407){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  return (isSubscribed ? <div className="subscription-box p-4 mt-4">
    <h5>Annual Subscription</h5>
    <p>{isCouponCodeValid ? <><s>$ {amount}{/*  / Per year */}</s> $ {amountAfterDiscount}{/*  / Per year */}</> : <>$ {amount}{/*  / Per year */}</>}</p>
    <div className="info-box">
      <span className="success-icon"></span>
      <span>Payment successful</span>
    </div>
  </div> : <>
    {clientSecret && (
      <Elements stripe={stripePromise} options={options}>
        {/* <CreditCard /> */}
        {demos.map(({ methodName, component: Component }, index) => {
          return (
            <div key={index}>
              {(() => {
                switch ("credit_card_elements") {
                  case methodName:
                    return (
                      <Component client_secret={clientSecret} handlePaymentSet={handlePaymentSet} token={token} handleLogout={handleLogout} amount={amount} />
                    );
                  default:
                    return (
                      <div></div>
                    );
                  }
              })()}
            </div>
          )
        })}
      </Elements>
    )}
  </>);
};

export default Payment;